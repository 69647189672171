<template>
  <input
    type="text"
    v-model="rawValue"
    @input="updateValue"
    @blur="formatValue"
    @focus="clearFormatting"
    placeholder="€400,00"
  />
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      rawValue: this.value || "",
      unforrmatedValue: "",
    };
  },
  computed: {
    formattedValue() {
      return this.formatCurrency(this.rawValue);
    },
  },
  methods: {
    formatCurrency(value) {
      const number = parseFloat(
        value.replace(",", ".").replace(/[^0-9.]/g, "")
      );
      return isNaN(number) ? "" : `€${number.toFixed(2).replace(".", ",")}`;
    },
    updateValue(event) {
      const target = event.target;
      this.rawValue = target.value.replace(/[^0-9,]/g, "");
    },
    formatValue() {
      this.rawValue = this.formattedValue;
    },
    clearFormatting() {
      this.rawValue = this.rawValue.replace(/[,€]/g, "");
    },
  },
  watch: {
    // formattedValue(value) {
    //   console.log("currency" + value);
    // },
    rawValue(value) {
      console.log("unformatted" + value);
      this.$emit("inputValue", value);
    },
  },
};
</script>

<style scoped>
input {
  background: #f9f9fa;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0 0 0 0 transparent;
  box-sizing: border-box;
  font-family: inherit;
  height: 100%;
  padding: 9px 12px;
  transition: box-shadow 0.15s ease-out 0.15s, border-color 0.15s,
    background 0.15s ease-out 0.15s, color 0.15s ease-out;
  width: 100%;
  border: transparent 2px solid;
  height: 38px !important;
  margin: 0px;
  height: 0px;
}
input:focus {
  border: transparent 2px solid !important;
  border-bottom: 2px solid #f7d901 !important;
  outline: none !important;
}

input:hover {
  border: 2px solid #f7d901;
}
</style>
