<template>
  <div class="locations-card ad-set-details-card mb-6 border-none">
    <v-card
      max-width="100%"
      class="pa-5 mb-5 rounded-lg card-content"
      style="overflow: visible"
      elevation="1"
    >
      <div class="card-header mb-4 d-flex align-center justify-space-between">
        <p>Your audience is located in:</p>
        <div class="d-flex align-center ga-5">
          <button type="button" @click="emptySelectedCountry">
            <span>
              <svg
                width="15"
                height="15"
                viewBox="0 0 16 16"
                fill="#33bbff"
                xmlns="http://www.w3.org/2000/svg"
                class="sds-icon sds-icon-refresh"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.72491 3.77886C6.45718 3.77886 5.24137 4.28247 4.34494 5.17889C3.44852 6.07531 2.94491 7.29113 2.94491 8.55886C2.94491 9.82509 3.44733 11.0395 4.34175 11.9356C5.23873 12.807 6.44377 13.2886 7.69436 13.2755C8.94646 13.2623 10.1425 12.7543 11.0212 11.8622L11.0249 11.8585L11.0286 11.8549C11.9184 10.9793 12.4321 9.79158 12.4607 8.54357L13.7937 8.57415C13.7572 10.1671 13.1021 11.6833 11.9674 12.8017C10.8418 13.9423 9.31094 14.5919 7.70838 14.6087C6.10408 14.6256 4.55828 14.007 3.40856 12.888L3.40852 12.888L3.40213 12.8816C2.25566 11.7352 1.61158 10.1802 1.61158 8.55886C1.61158 6.9375 2.25566 5.38255 3.40213 4.23608C4.54861 3.08961 6.10356 2.44553 7.72491 2.44553C9.26819 2.44553 10.7513 3.02907 11.8793 4.07396L11.9832 3.06022L13.3096 3.19626L12.99 6.31284L9.87781 6.61549L9.74875 5.28842L11.0844 5.15853C10.19 4.27484 8.98302 3.77886 7.72491 3.77886ZM11.8415 4.44229L11.5763 4.70748L11.8416 4.44136L11.8415 4.44229Z"
                ></path>
              </svg>
            </span>
            <span class="btn-text">Rest</span>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="country-select">
          <p
            class="d-flex align-center justify-space-between"
            v-for="country in selectedCountries"
            :key="country.id"
          >
            <span class="country d-flex">{{ country.label }}</span>
            <span @click="removeElementFromArray(country)" class="cross">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="#33bbff"
                xmlns="http://www.w3.org/2000/svg"
                class="sds-icon sds-icon-cross"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
                ></path>
              </svg>
            </span>
          </p>
        </div>
        <treeselect
          v-model="selectedCountries"
          :multiple="true"
          :options="countryList"
          :value-format="'object'"
        >
        </treeselect>
      </div>
    </v-card>
  </div>
</template>

<script>
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";

export default {
  components: {
    Treeselect,
  },
  data() {
    return {
      selectedCountries: [],
      countryList: [
        {
          id: 1,
          label: "South America",
          children: [
            { id: 2, label: "California" },
            { id: 3, label: "Colorado" },
            { id: 4, label: "Florida" },
            { id: 5, label: "Georgia" },
            { id: 6, label: "Texas" },
            { id: 7, label: "Wyoming" },
          ],
        },
        {
          id: 8,
          label: "Europe",
          children: [
            { id: 9, label: "France" },
            { id: 10, label: "Germany" },
            { id: 11, label: "Spain" },
            { id: 12, label: "Italy" },
            { id: 13, label: "Portugal" },
          ],
        },
        {
          id: 14,
          label: "Asia",
          children: [
            { id: 15, label: "China" },
            { id: 16, label: "Japan" },
            { id: 17, label: "India" },
            { id: 18, label: "South Korea" },
            { id: 19, label: "Thailand" },
          ],
        },
      ],
    };
  },
  methods: {
    removeElementFromArray(country) {
      this.selectedCountries = this.selectedCountries.filter(
        (item) => item.id !== country.id
      );
    },
    emptySelectedCountry() {
      this.selectedCountries = [];
    },
  },
};
</script>

<style scoped>
.locations-card {
  border: none;
}
</style>
