export default function authMiddleware(to, from, next) {
  if (to.matched.some((record) => record.meta.auth == 'auth')) {
    if (!localStorage.getItem("user_token")) {
      next({ name: "LoginPage" });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.auth) == 'unauth') {
    if (!localStorage.getItem("user_token")) {
      next();
    } else {
      next({ name: "HomePage" });
    }
  } else {
    next();
  }
}