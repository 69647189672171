import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest";
import { toast } from "vue3-toastify";
import { useRouter } from "vue-router";

export const useAwarenessStore = defineStore("awareness", {
  state: () => ({
    adsquads: [],
    ads: [],
    organization_id: "",
    public_profile_id: "",
    account_id: "",
    img: "",
    creative: [],
    campaigns: [],
    validationData: [],
  }),

  actions: {
    async sendCreative() {
      try {
        const response = await this.getAllOrganizations();
        // return response;
        if (response.data.key === "success") {
          const router = useRouter();
          setTimeout(() => {
            router.push({ name: "CreateNewCamp" });
          }, 2000);
        }
      } catch (error) {
        console.error("Error during creative creation:", error);
        throw error;
      }
    },

    async getAllOrganizations() {
      try {
        const response = await apiClient.get("/snapchat/get/all-organizations");
        this.organization_id =
          response.data.data.organizations[0].organization.id;
        const r = await this.getUserProfileId();
        return r;
      } catch (error) {
        console.error("Error fetching organization data:", error);
        throw error;
      }
    },

    async getUserProfileId() {
      try {
        const response = await apiClient.get(
          `/snapchat/user/${this.organization_id}`
        );
        this.public_profile_id =
          response.data.data.public_profiles[0].public_profile.id;
        const r = await this.getAllAccounts();
        return r;
      } catch (error) {
        console.error("Error fetching user profile:", error);
        throw error;
      }
    },

    async getAllAccounts() {
      try {
        const response = await apiClient.get(
          `/snapchat/get/all/ad-accounts/${this.organization_id}`
        );
        this.account_id = response.data.data.id;
        const r = await this.create();
        return r;
      } catch (error) {
        console.error("Error fetching accounts:", error);
        throw error;
      }
    },

    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(",")[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }

      return new Blob([uintArray]);
    },

    createFileFromBlob(blob, name, type, size) {
      return new File([blob], name, { type, size });
    },

    getFileFromStorage(key) {
      const storedData = JSON.parse(localStorage.getItem(key));
      if (!storedData) return null;

      const { name, type, size, content } = storedData;
      const blob = this.dataURItoBlob(content);
      return this.createFileFromBlob(blob, name, type, size);
    },

    getFromLocalStorage(key, defaultValue = "") {
      return localStorage.getItem(key) || defaultValue;
    },

    async getFileFromIndexedDB(fileId) {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open("FileStorage", 1);

        request.onerror = () => reject(new Error("Failed to open IndexedDB"));

        request.onsuccess = (event) => {
          const db = event.target.result;
          const transaction = db.transaction(["files"], "readonly");
          const store = transaction.objectStore("files");
          const getRequest = store.get(fileId);

          getRequest.onsuccess = () => {
            const fileData = getRequest.result;
            if (fileData) {
              const { name, type, size, content } = fileData;
              const blob = this.dataURItoBlob(content);
              resolve(this.createFileFromBlob(blob, name, type, size));
            } else {
              resolve(null);
            }
          };

          getRequest.onerror = () =>
            reject(new Error("Failed to retrieve file from IndexedDB"));
        };
      });
    },

    async validateCreative() {
      this.validationData = [];
      try {
        const [topsnapFile, iconMediaFile] = await Promise.all([
          this.getFileFromIndexedDB("single_image_or_video_tosnap_file").catch(
            () => null
          ),
          this.getFileFromIndexedDB("image_app_icon_file").catch(() => null),
        ]);
        this.creative = {
          name: localStorage.getItem("ad_name"),
          type: localStorage.getItem("selected_ad_type"),
          brand_name: localStorage.getItem("brand_name"),
          headline: localStorage.getItem("headline"),
          file: topsnapFile,
          top_snap_crop_position:
            localStorage.getItem("top_snap_crop_position") || "MIDDLE",
          ad_account_id: this.account_id,
          profile_properties: {
            profile_id: this.public_profile_id,
          },
          call_to_action: localStorage.getItem("call_to_action"),
          app_install_properties:
            localStorage.getItem("selected_ad_type") === "APP_INSTALL"
              ? {
                  app_name: localStorage.getItem("app_name"),
                  ios_app_id: localStorage.getItem("ios_app_id"),
                  android_app_url: localStorage.getItem("andriod_app_url"),
                  icon_media_id: iconMediaFile,
                  product_page_id: localStorage.getItem(
                    "custom_ios_product_page_id"
                  ),
                }
              : null,
          shareable: localStorage.getItem("shareable"),
          web_view_properties:
            localStorage.getItem("selected_ad_type") === "WEB_VIEW"
              ? {
                  url: localStorage.getItem("website_url"),
                  block_preload: true,
                }
              : null,
          deep_link_properties:
            localStorage.getItem("selected_ad_type") === "DEEP_LINK"
              ? {
                  deep_link_uri: localStorage.getItem("deep_link_uri"),
                  app_name: localStorage.getItem("app_name"),
                  ios_app_id: localStorage.getItem("ios_app_id"),
                  android_app_url: localStorage.getItem("android_app_url"),
                  fallback_type: localStorage.getItem("fallback_type"),
                  web_view_fallback_url: localStorage.getItem(
                    "fallback_website_url"
                  ),
                  product_page_id:
                    localStorage.getItem("custom_ios_product_page_id") ||
                    "1234",
                  icon_media_id: iconMediaFile, // Ensures iconMediaFile is handled correctly
                }
              : null,
        };

        let CreativeValidations = [];

        if (!this.creative.name) {
          CreativeValidations.push({
            message: "Ad Name is required",
            key: "Name",
            id: "name",
          });
        }
        if (!this.creative.type) {
          CreativeValidations.push({
            message: "Ad Type is required",
            key: "Type",
            id: "type",
          });
        }
        if (!this.creative.brand_name) {
          CreativeValidations.push({
            message: "Brand Name is required",
            key: "brand name",
            id: "brand_name",
          });
        }
        if (!this.creative.headline) {
          CreativeValidations.push({
            message: "Headline is required",
            key: "headline",
            id: "headline",
          });
        }
        if (!this.creative.file) {
          CreativeValidations.push({
            message: "File is required",
            key: "snaptop media",
            id: "snaptop_media",
          });
        }
        if (!this.creative.call_to_action) {
          CreativeValidations.push({
            message: "Call to Action is required",
            key: "call to action",
            id: "call_to_action",
          });
        }
        if (!this.creative.profile_properties.profile_id) {
          CreativeValidations.push({
            message: "Profile ID is required",
            key: "profile properties profile id",
            id: "profile_properties_profile_id",
          });
        }
        switch (this.creative.type) {
          case "APP_INSTALL":
            if (!this.creative.app_install_properties.app_name) {
              CreativeValidations.push({
                message: `The app name field is required when type is ${this.creative.type}`,
                key: "app name",
                id: "app_name",
              });
            }
            if (!this.creative.app_install_properties.ios_app_id) {
              CreativeValidations.push({
                message: `The ios app id field is required when type is ${this.creative.type}`,
                key: "ios app id",
                id: "ios_app_id",
              });
            }
            if (!this.creative.app_install_properties.android_app_url) {
              CreativeValidations.push({
                message: `The android app url field is required when type is ${this.creative.type}`,
                key: "android app url",
                id: "android_app_url",
              });
            }
            if (!this.creative.app_install_properties.icon_media_id) {
              CreativeValidations.push({
                message: `The icon media id field is required when type is ${this.creative.type}`,
                key: "icon media id",
                id: "icon_media_id",
              });
            }
            if (!this.creative.app_install_properties.product_page_id) {
              CreativeValidations.push({
                message: `The product page id field is required when type is ${this.creative.type}`,
                key: "product page id",
                id: "product_page_id",
              });
            }
            break;

          case "WEB_VIEW":
            if (!this.creative.web_view_properties.url) {
              CreativeValidations.push({
                message: `The website URL field is required when type is ${this.creative.type}`,
                key: "website URL",
                id: "website_url",
              });
            }
            break;

          case "DEEP_LINK":
            if (!this.creative.deep_link_properties.deep_link_uri) {
              CreativeValidations.push({
                message: `The deep link URI field is required when type is ${this.creative.type}`,
                key: "deep link URI",
                id: "deep_link_uri",
              });
            }
            if (!this.creative.deep_link_properties.app_name) {
              CreativeValidations.push({
                message: `The app name field is required when type is ${this.creative.type}`,
                key: "app name",
                id: "app_name",
              });
            }
            if (!this.creative.deep_link_properties.ios_app_id) {
              CreativeValidations.push({
                message: `The ios app id field is required when type is ${this.creative.type}`,
                key: "ios app id",
                id: "ios_app_id",
              });
            }
            if (!this.creative.deep_link_properties.android_app_url) {
              console.log(
                "asdasd" + this.creative.deep_link_properties.android_app_url
              );

              CreativeValidations.push({
                message: `The android app url field is required when type is ${this.creative.type}`,
                key: "android app url",
                id: "android_app_url",
              });
            }
            if (!this.creative.deep_link_properties.icon_media_id) {
              CreativeValidations.push({
                message: `The icon media id field is required when type is ${this.creative.type}`,
                key: "icon media id",
                id: "icon_media_id",
              });
            }
            if (!this.creative.deep_link_properties.product_page_id) {
              CreativeValidations.push({
                message: `The product page id field is required when type is ${this.creative.type}`,
                key: "product page id",
                id: "product_page_id",
              });
            }
            if (
              this.creative.deep_link_properties.fallback_type === "WEB" &&
              !this.creative.deep_link_properties.web_view_fallback_url
            ) {
              CreativeValidations.push({
                message: `The fallback website URL field is required when fallback type is WEB for ${this.creative.type}`,
                key: "fallback website URL",
                id: "fallback_website_url",
              });
            }
            break;

          default:
            break;
        }

        if (CreativeValidations.length > 0) {
          this.validationData.push({
            type: "creative",
            error: "validation",
            data: CreativeValidations,
            path: "SnapThirdStep",
          });
        }
      } catch (error) {
        console.error("Error in validateCreative:", error);
        throw error;
      }
    },

    async validateCampaigns() {
      try {
        this.campaigns = [
          {
            name: localStorage.getItem("campaign_name"),
            ad_account_id: this.account_id,
            type: "marketing",
            budget:
              localStorage.getItem("selected_budget") === "daily_budget"
                ? parseFloat(
                    localStorage
                      .getItem("daily_budget_micro")
                      ?.replace("€", "")
                      ?.replace(",", ".")
                      ?.trim()
                  )
                : parseFloat(
                    localStorage
                      .getItem("lifetime_spend_cap_micro")
                      ?.replace("€", "")
                      ?.replace(",", ".")
                      ?.trim()
                  ),
            platform: "snapchat",
            objective: "BRAND_AWARENESS",
            status:
              localStorage.getItem("status") === "true" ? "ACTIVE" : "PAUSED",
            start_time: localStorage.getItem("campaign_start_date"),
            end_time: localStorage.getItem("campaign_end_date"),
            lifetime_spend_cap_micro:
              localStorage.getItem("selected_budget") === "lifetime_budget"
                ? parseFloat(
                    localStorage
                      .getItem("lifetime_spend_cap_micro")
                      ?.replace("€", "")
                      ?.replace(",", ".")
                      ?.trim()
                  ) * 1000000
                : null,
            buy_model: "AUCTION",
            measurement_spec: {
              ios_app_id: "1234",
              android_app_url: "com.snapchat.android",
            },
            daily_budget_micro:
              localStorage.getItem("selected_budget") === "daily_budget"
                ? parseFloat(
                    localStorage
                      .getItem("daily_budget_micro")
                      ?.replace("€", "")
                      ?.replace(",", ".")
                      ?.trim()
                  ) * 1000000
                : null,
            objective_v2_properties: this.getCampaignType(),
            regulations: {
              restricted_delivery_signals: false,
            },
            promotion_type:
              localStorage.getItem("promote-places") === "true"
                ? "PROMOTE_PLACES"
                : null,
          },
        ];

        const objectives = [
          "BRAND_AWARENESS",
          "APP_CONVERSION",
          "APP_INSTALL",
          "CATALOG_SALES",
          "ENGAGEMENT",
          "LEAD_GENERATION",
          "VIDEO_VIEW",
          "WEB_CONVERSION",
          "PROMOTE_STORIES",
          "PROMOTE_PLACES",
        ];

        const campaignValidations = [];

        this.campaigns.forEach((campaign, index) => {
          if (!campaign.name) {
            campaignValidations.push({
              message: "Campaign name is required.",
              key: `campaigns name`,
              id: `campaigns[${index}].name`,
            });
          }

          if (!campaign.ad_account_id) {
            campaignValidations.push({
              message: "Ad account ID is required.",
              key: `campaigns[${index}].ad_account_id`,
            });
          }

          if (
            !campaign.status ||
            !["ACTIVE", "PAUSED"].includes(campaign.status)
          ) {
            campaignValidations.push({
              message:
                "Status is required and must be either 'ACTIVE' or 'PAUSED'.",
              key: `campaigns status`,
              id: `campaigns[${index}].status`,
            });
          }

          if (!campaign.objective || !objectives.includes(campaign.objective)) {
            campaignValidations.push({
              message: `Objective is required and must be one of: ${objectives.join(
                ", "
              )}.`,
              key: `campaigns objective`,
              id: `campaigns[${index}].objective`,
            });
          }

          if (
            !campaign.start_time ||
            isNaN(new Date(campaign.start_time).getTime())
          ) {
            campaignValidations.push({
              message: "Start time is required and must be a valid date.",
              key: `campaigns start time`,
              id: `campaigns[${index}].start_time`,
            });
          }
          if (
            !campaign.end_time ||
            isNaN(new Date(campaign.end_time).getTime())
          ) {
            campaignValidations.push({
              message: "End time is required and must be a valid date.",
              key: `campaigns End time`,
              id: `campaigns[${index}].end_time`,
            });
          }
          if (
            campaign.objective_v2_properties &&
            Array.isArray(campaign.objective_v2_properties)
          ) {
            const validProperties = [
              "AWARENESS_AND_ENGAGEMENT",
              "SALES",
              "TRAFFIC",
              "APP_PROMOTION",
              "LEADS",
            ];
            campaign.objective_v2_properties.forEach((property) => {
              if (!validProperties.includes(property)) {
                campaignValidations.push({
                  message: `Invalid objective_v2_property: ${property}.`,
                  key: `campaigns objective v2 properties`,
                  id: `campaigns[${index}].objective_v2_properties`,
                });
              }
            });
          }
        });

        if (campaignValidations.length > 0) {
          this.validationData.push({
            type: "campaigns",
            error: "validation",
            data: campaignValidations,
            path: "SnapFirstStep",
          });
        }
      } catch (error) {
        console.error("Error in validateCampaigns:", error);
        throw error;
      }
    },
    getGeos() {
      const selectedCountries = JSON.parse(
        localStorage.getItem("selected_countries") || "[]"
      ).filter(
        (country) =>
          country &&
          typeof country === "object" &&
          "id" in country &&
          "label" in country &&
          "code" in country
      );
      let geos = [];
      const selectedRegions = JSON.parse(
        localStorage.getItem("selectedRegions") || "[]"
      );
      selectedCountries.forEach((country) => {
        const regions = country.children || [];
        const selected_regions = regions
          .filter((region) =>
            selectedRegions.some(
              (selectedRegion) => selectedRegion.id === region.id
            )
          )
          .map((region) => region.id);
        geos.push({
          country_code: country.code,
          selected_regions,
        });
      });
      // return geos;
      return [
        {
          country_code: "us",
        },
      ];
    },
    getCarriersIds() {
      let ids = [];
      if (localStorage.getItem("selected_carriers")) {
        ids = JSON.parse(localStorage.getItem("selected_carriers")).map(
          (carrier) => carrier.id
        );
      }
      return ids;
    },
    async validateAdsquads() {
      try {
        this.adsquads = [
          {
            name: localStorage.getItem("ad_set_details"),
            type: "SNAP_ADS",
            optimization_goal:
              localStorage.getItem("selected_goal") || "IMPRESSIONS",
            bid_micro:
              parseFloat(localStorage.getItem("goal_bid_budget")) * 1000000,
            billing_event: "IMPRESSION",
            start_time:
              localStorage.getItem("selected_budget") === "daily_budget"
                ? localStorage.getItem("daily_budget_start_date")
                : localStorage.getItem("budget_start_date"),
            end_time:
              localStorage.getItem("selected_budget") === "daily_budget"
                ? localStorage.getItem("daily_budget_end_date")
                : localStorage.getItem("budget_end_date"),
            country_code: "us",
            lifetime_budget_micro:
              localStorage.getItem("selected_budget") === "lifetime_budget"
                ? parseFloat(
                    localStorage
                      .getItem("lifetime_spend_cap_micro")
                      ?.replace("€", "")
                      ?.replace(",", ".")
                      ?.trim()
                  ) * 1000000
                : null,
            daily_budget_micro:
              localStorage.getItem("selected_budget") === "daily_budget"
                ? parseFloat(
                    localStorage
                      .getItem("daily_budget_micro")
                      ?.replace("€", "")
                      ?.replace(",", ".")
                      ?.trim()
                  ) * 1000000
                : null,
            status:
              localStorage.getItem("ad_set_status") === "true"
                ? "ACTIVE"
                : "PAUSED",
            story_ad_creative_type: this.creative?.type,

            cap_and_exclusion_config: {
              frequency_cap_config: [
                {
                  frequency_cap_count: 4,
                  time_interval: 48,
                  frequency_cap_interval: "HOURS",
                  frequency_cap_type: "IMPRESSIONS",
                },
              ],
            },
            placement_v2: {
              config: this.getPlacementConfig(),
              platforms:
                this.getPlacementConfig() === "CUSTOM" ? ["SNAPCHAT"] : null,
              snapchat_positions:
                this.getPlacementConfig() === "CUSTOM"
                  ? this.getSnapchatPositions()
                  : null,
              inclusion: this.getinclusionConfig(),
              exclusion: this.getexclusionConfig(),
            },
            brand_safety_config:
              localStorage.getItem("brand_safety_filter") === "full_inventory"
                ? "FULL_INVENTORY"
                : "LIMITED_INVENTORY",
            child_ad_type: "SNAP_AD",
            conversion_window: "SWIPE_28DAY_VIEW_1DAY",
            gender: localStorage.getItem("gender"),
            targeting: {
              devices: [
                {
                  os_type: this.getOsType(),
                  operation: "INCLUDE",
                  os_version_min: this.getOsVersionMin(),
                  os_version_max: this.getOsVersionMax(),
                  carrier_id: [...this.getCarriersIds()],
                },
              ],
              demographics: [
                {
                  min_age: parseInt(
                    Number(
                      localStorage.getItem(
                        "age_restrictions_required_start_age"
                      )
                    )
                  ),
                  max_age: parseInt(
                    Number(
                      localStorage.getItem(
                        "age_restrictions_required_start_end_age"
                      )
                    )
                  ),
                  languages: JSON.parse(
                    localStorage.getItem("selected_language")
                  )?.map((lang) => lang.id),
                },
              ],
              geos: this.getGeos(),
              regulated_content: true,
            },
            ad_squad_status:
              localStorage.getItem("ad_set_status") === "true"
                ? "ACTIVE"
                : "PAUSED",
            measurement_provider_names: ["DOUBLEVERIFY"],
          },
        ];

        let validationErrors = [];

        this.adsquads.forEach((ad, index) => {
          if (!ad.name) {
            validationErrors.push({
              message: `Name is required for adsquad at index ${index}`,
              key: `ads name`,
              id: `adsquads[${index}].name`,
            });
          }

          if (!ad.type) {
            validationErrors.push({
              message: `Type is required for adsquad at index ${index}`,
              key: `ads type`,
              id: `adsquads[${index}].type`,
            });
          }
          if (!ad.bid_micro) {
            validationErrors.push({
              message: `Bid Budget is required for adsquad at index ${index}`,
              key: `Bid Budget`,
              id: `adsquads[${index}].bid_micro`,
            });
          }
          if (!ad.targeting.demographics[0].min_age) {
            validationErrors.push({
              message: `Min age is required for adsquad at index ${index}`,
              key: `min age`,
              id: `adsquads[${index}].min_age`,
            });
          }
          if (!ad.targeting.demographics[0].max_age) {
            validationErrors.push({
              message: `Max age is required for adsquad at index ${index}`,
              key: `max age`,
              id: `adsquads[${index}].max_age`,
            });
          }
          if (
            ![
              "IMPRESSIONS",
              "APP_INSTALLS",
              "SWIPES",
              "PIXEL_PURCHASE",
              "PIXEL_SIGNUP",
              "APP_PURCHASE",
              "APP_REENGAGE_OPEN",
            ].includes(ad.optimization_goal)
          ) {
            validationErrors.push({
              message: `Invalid optimization goal for adsquad at index ${index}`,
              key: `ads optimization goal`,
              id: `adsquads[${index}].optimization_goal`,
            });
          }

          if (!ad.billing_event) {
            validationErrors.push({
              message: `Billing event is required for adsquad at index ${index}`,
              key: `ads billing event`,
              id: `adsquads[${index}].billing_event`,
            });
          }

          if (!ad.start_time) {
            validationErrors.push({
              message: `Start time is required for adsquad at index ${index}`,
              key: `ads start time`,
              id: `adsquads[${index}].start_time`,
            });
          }

          if (!ad.end_time) {
            validationErrors.push({
              message: `End time is required for adsquad at index ${index}`,
              key: `ads end time`,
              id: `adsquads[${index}].end_time`,
            });
          }

          if (ad.lifetime_budget_micro && ad.daily_budget_micro) {
            validationErrors.push({
              message: `Cannot set both daily and lifetime budgets for adsquad at index ${index}`,
              key: `ads budget conflict`,
              id: `adsquads[${index}].budget_conflict`,
            });
          }
          if (localStorage.getItem("selected_budget") === "daily_budget") {
            if (!ad.daily_budget_micro) {
              validationErrors.push({
                message: `Daily budget is required for adsquad at index ${index}`,
                key: `ads daily budget`,
                id: `adsquads[${index}].daily_budget_micro`,
              });
            }
          } else {
            if (!ad.lifetime_budget_micro) {
              validationErrors.push({
                message: `Lifetime budget is required for adsquad at index ${index}`,
                key: `ads lifetime budget`,
                id: `adsquads[${index}].lifetime_budget_micro`,
              });
            }
          }
          if (ad.bid_micro === null && ad.optimization_goal === "IMPRESSIONS") {
            validationErrors.push({
              message: `Bid micro is required when optimization goal is IMPRESSIONS for adsquad at index ${index}`,
              key: `ads bid micro`,
              id: `adsquads[${index}].bid_micro`,
            });
          }
        });

        if (validationErrors.length > 0) {
          this.validationData.push({
            type: "adsquad",
            error: "validation",
            data: validationErrors,
            path: "SnapSecoundStep",
          });
        }
      } catch (error) {
        console.error("Error in validateAdsquads:", error);
        throw error;
      }
    },

    async create() {
      try {
        await this.validateCreative();
        await this.validateCampaigns();
        await this.validateAdsquads();

        const data = {};
        if (this.validationData.length > 0) {
          data.error = "validation";
          data.data = this.validationData;
          return data;
        }

        const formData = new FormData();
        this.appendFormData(formData, { creatives: [this.creative] });
        for (const [key, value] of formData.entries()) {
          console.log(`${key}: ${value}`);
        }
        const response = await apiClient.post(
          "/snapchat/create/creative",
          formData
        );
        console.log("Creative created successfully:", response);
        const r = await this.createcampaign(
          response.data.data.creatives[0].creative
        );
        return r;
      } catch (error) {
        console.error("Error creating creative:", error);
        throw error;
      }
    },

    async createcampaign(creative = null) {
      try {
        const campaigns = this.campaigns;
        const adsquads = this.adsquads;
        let adsType = "";
        switch (creative?.type) {
          case "APP_INSTALL":
            adsType = "APP_INSTALL";
            break;
          case "SNAP_AD":
            adsType = "SNAP_AD";

            break;
          case "WEB_VIEW":
            adsType = "REMOTE_WEBPAGE";

            break;
          case "DEEP_LINK":
            adsType = "DEEP_LINK";

            break;
          case "COMPOSITE":
            adsType = "STORY";

            break;
          case "AD_TO_LENS":
            adsType = "AD_TO_LENS";

            break;
          case "AD_TO_CALL":
            adsType = "AD_TO_CALL";

            break;
          case "AD_TO_MESSAGE":
            adsType = "AD_TO_MESSAGE";

            break;
          case "LENS":
            adsType = "LENS";

            break;
          case "LENS_WEB_VIEW":
            adsType = "LENS_REMOTE_WEBPAGE";

            break;
          case "LENS_APP_INSTALL":
            adsType = "LENS_APP_INSTALL";

            break;
          case "LENS_DEEP_LINK":
            adsType = "LENS_DEEP_LINK";

            break;
          case "COLLECTION":
            adsType = "COLLECTION";

            break;
          case "LEAD_GENERATION":
            adsType = "LEAD_GENERATION";

            break;
          default:
            break;
        }
        const ads = [
          {
            creative_id: creative?.id,
            name: creative?.name,
            type: adsType,
            status:
              localStorage.getItem("delivery_status") === "true"
                ? "ACTIVE"
                : "PAUSED",
          },
        ];

        const formData = new FormData();
        this.appendFormData(formData, { campaigns });
        this.appendFormData(formData, { adsquads });
        this.appendFormData(formData, { ads });
        for (const [key, value] of formData.entries()) {
          console.log(`${key}: ${value}`);
        }
        const response = await apiClient.post(
          "/snapchat/create/campaign",
          formData
        );
        console.log("Campaign created successfully:", response);

        if (response.data.key === "success") {
          toast(`Campaign & Creative created successfully`, {
            type: "success",
          });
          return response;
        }
      } catch (error) {
        console.error("Error creating campaign:", error);
        throw error;
      }
    },

    Resetfiles(dbName) {
      const request = indexedDB.open(dbName);

      request.onsuccess = function (event) {
        const db = event.target.result;
        const transaction = db.transaction(db.objectStoreNames, "readwrite");

        transaction.oncomplete = function () {
          console.log("All object stores cleared successfully.");
        };

        transaction.onerror = function (event) {
          console.error("Transaction error:", event.target.error);
        };

        for (const storeName of db.objectStoreNames) {
          const objectStore = transaction.objectStore(storeName);
          objectStore.clear().onsuccess = function () {
            console.log(`Cleared object store: ${storeName}`);
          };
        }
      };

      request.onerror = function (event) {
        console.error("Error opening database:", event.target.error);
      };
    },
    getCampaignType() {
      switch (localStorage.getItem("selectedOption")) {
        case "Awareness & Engagement":
          return "BRAND_AWARENESS";
        case "Traffic":
          return "TRAFFIC";
        case "Leads":
          return "LEADS";
        case "App Promotion":
          return "APP_PROMOTION";
        case "Sales":
          return "SALES";
        default:
          return "UNKNOWN";
      }
    },

    getCampaignObjective() {
      switch (localStorage.getItem("selectedOption")) {
        case "Awareness & Engagement":
          return "AWARENESS_AND_ENGAGEMENT";
        case "Traffic":
          return "TRAFFIC";
        case "Leads":
          return "LEADS";
        case "App Promotion":
          return "APP_PROMOTION";
        case "Sales":
          return "SALES";
        default:
          return "UNKNOWN";
      }
    },

    getPlacementConfig() {
      return localStorage.getItem("placement_type") === "edit_placement"
        ? "CUSTOM"
        : "AUTOMATIC";
    },

    getSnapchatPositions() {
      if (localStorage.getItem("edit_placement_type") === "camera") {
        return ["CAMERA"];
      }

      const positions = [];
      if (localStorage.getItem("between_content_user_stories") === "true")
        positions.push("INTERSTITIAL_USER");
      if (
        localStorage.getItem(
          "between_content_publisher_and_creator_stories"
        ) === "true"
      )
        positions.push("INTERSTITIAL_CONTENT");
      if (localStorage.getItem("between_content_spotlight") === "true")
        positions.push("INTERSTITIAL_SPOTLIGHT");
      if (localStorage.getItem("discover_feed") === "true")
        positions.push("FEED");
      if (localStorage.getItem("within_content") === "true") {
        positions.push("INTERSTITIAL_USER");
      }
      return positions;
    },
    getinclusionConfig() {
      const positions = [];
      if (
        localStorage.getItem("within_content") === "true" &&
        localStorage.getItem("within_content_publisher_stories") === "true" &&
        localStorage.getItem("publisher_stories_selected_categories_types") ===
          "include"
      ) {
        positions.push({
          content_types: [
            localStorage.getItem("publisher_stories_selected_categories"),
          ],
        });
      }
      if (
        localStorage.getItem("within_content") === "true" &&
        localStorage.getItem("within_content_creator_stories") === "true" &&
        localStorage.getItem("creator_stories_selected_categories_types") ===
          "include"
      ) {
        positions.push({
          content_types: [
            localStorage.getItem("creator_stories_selected_categories"),
          ],
        });
      }
      return positions;
    },
    getexclusionConfig() {
      const positions = [];
      if (
        localStorage.getItem("within_content") === "true" &&
        localStorage.getItem("within_content_publisher_stories") === "true" &&
        localStorage.getItem("publisher_stories_selected_categories_types") ===
          "exclude"
      ) {
        positions.push({
          content_types: [
            localStorage.getItem("publisher_stories_selected_categories"),
          ],
        });
      }
      if (
        localStorage.getItem("within_content") === "true" &&
        localStorage.getItem("within_content_creator_stories") === "true" &&
        localStorage.getItem("creator_stories_selected_categories_types") ===
          "exclude"
      ) {
        positions.push({
          content_types: [
            localStorage.getItem("creator_stories_selected_categories"),
          ],
        });
      }
      return positions;
    },
    getOsType() {
      if (localStorage.getItem("iOS_devices") === "true") return "iOS";
      if (localStorage.getItem("android_devices") === "true") return "ANDROID";
      return "";
    },

    getOsVersionMin() {
      return this.getOsType() === "iOS"
        ? localStorage.getItem("min_ios_version")
        : localStorage.getItem("min_android_version");
    },

    getOsVersionMax() {
      return this.getOsType() === "iOS"
        ? localStorage.getItem("max_ios_version")
        : localStorage.getItem("max_android_version");
    },

    getAdSchedulingConfig() {
      return {
        tuesday: {
          hour_of_day: [1, 2],
        },
      };
    },

    appendFormData(formData, data, parentKey = "") {
      if (data && typeof data === "object" && !(data instanceof File)) {
        Object.entries(data).forEach(([key, value]) => {
          const newKey = parentKey ? `${parentKey}[${key}]` : key;
          this.appendFormData(formData, value, newKey);
        });
      } else if (data !== undefined && data !== null) {
        formData.append(parentKey, data);
      }
    },
  },
});
