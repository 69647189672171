<template>
    <div>
      <canvas ref="barChart"></canvas>
    </div>
  </template>
  
  <script>
  import { Chart, registerables } from "chart.js";
  
  Chart.register(...registerables); // Register all Chart.js components
  
  export default {
    name: "VerticalBarChart",
    props: {
      chartData: {
        type: Object,
        required: true,
      },
      chartOptions: {
        type: Object,
        default: () => ({}),
      },
    },
    mounted() {
      this.renderChart();
    },
    methods: {
      renderChart() {
        const ctx = this.$refs.barChart.getContext("2d");
        new Chart(ctx, {
          type: "bar",
          data: this.chartData,
          options: this.chartOptions,
        });
      },
    },
  };
  </script>
  