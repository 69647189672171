<template>
  <v-container
    fluid
    style="margin-top: 0%; padding: 0% 0% 0 0; min-height: calc(100vh - 58px)"
  >
    <v-row style="height: 100%; margin: 0; padding: 0; position: relative">
      <v-col cols="12" class="pa-0">
        <GlobalNav />
      </v-col>
      <v-col
        cols="12"
        style="height: 100%"
        class="pa-0 d-flex align-start ga-6"
      >
        <SideBar />
        <div class="ad-performance-contant">
          <BreadCrumb :linksData="linksData" class="mb-5" />
          <div class="result-last-days">
            <h4 class="mb-3">Last 30 Days</h4>
            <v-container fluid class="py-0 px-1">
              <v-row class="w-100 pa-0 ma-0">
                <v-col
                  cols="11"
                  xl="12"
                  md="12"
                  sm="12"
                  class="results-card ga-8 pa-0"
                >
                  <v-card class="card rounded-xl px-7 py-12">
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img
                          src="@/assets/soft-ware-img/ads-performance-campaign.svg"
                          alt=""
                        />
                      </div>
                      <div class="result-contain">
                        <p class="number">
                          {{ campaignNumber }}
                        </p>
                        <p class="title">Compaigns</p>
                      </div>
                    </div>
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img
                          src="@/assets/soft-ware-img/amount-icon-dollar.svg"
                          alt=""
                        />
                      </div>
                      <div class="result-contain">
                        <p class="number">{{ amountSpent }}$</p>
                        <p class="title">Amount Spent</p>
                      </div>
                    </div>
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img
                          src="@/assets/soft-ware-img/impressions-icon.svg"
                          alt=""
                        />
                      </div>
                      <div class="result-contain">
                        <p class="number">{{ impressions }}$</p>
                        <p class="title">Impressions</p>
                      </div>
                    </div>
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img
                          src="@/assets/soft-ware-img/leads-ad-performance-icon.svg"
                          alt=""
                        />
                      </div>
                      <div class="result-contain">
                        <p class="number">{{ leads }}</p>
                        <p class="title">Leads</p>
                      </div>
                    </div>
                    <div class="compaigns-result d-flex align-center">
                      <div class="icon-contain">
                        <img
                          src="@/assets/soft-ware-img/click-icon.svg"
                          alt=""
                        />
                      </div>
                      <div class="result-contain">
                        <p class="number">{{ clicks }}</p>
                        <p class="title">Click</p>
                      </div>
                    </div>
                  </v-card>
                  <div class="actions-on-performance">
                    <button
                      @click="dialog = true"
                      class="add-campaign-btn d-flex align-center ga-1"
                    >
                      <span class="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="20px"
                          viewBox="0 -960 960 960"
                          width="20px"
                          fill="#fff"
                        >
                          <path
                            d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"
                          />
                        </svg>
                      </span>
                      <span>Add Compaign</span>
                    </button>
                    <v-dialog
                      v-model="dialog"
                      width="auto"
                      class="add-campaign-modal"
                    >
                      <v-card
                        min-width="600"
                        class="dialog-card pa-4 rounded-xl"
                      >
                        <div
                          class="card-title d-flex justify-space-between align-center"
                        >
                          <p>Select Social Media Platform</p>
                          <img
                            @click="dialog = false"
                            src="@/assets/soft-ware-img/X circle.svg"
                            alt=""
                          />
                        </div>
                        <v-card-text>
                          <div>
                            <router-link
                              to="/create-new-camp"
                              style="border: 1px solid #1094f4"
                            >
                              <span>
                                <img
                                  src="@/assets/soft-ware-img/facebook-icon-adcampaign.svg"
                                  alt=""
                                />
                              </span>
                              <span> Facebook </span>
                            </router-link>
                            <router-link
                              to="/create-new-camp"
                              style="border: 0.6px solid #b731a0"
                            >
                              <span>
                                <img
                                  src="@/assets/soft-ware-img/instgrapm.svg"
                                  alt=""
                                />
                              </span>

                              <span> Instagram </span>
                            </router-link>
                            <router-link
                              to="/create-new-camp"
                              style="border: 1px solid #fffa37"
                            >
                              <span>
                                <img
                                  src="@/assets/soft-ware-img/snapchat-icoon-ccampaign.svg"
                                  alt=""
                                />
                              </span>
                              <span> Snapchat </span>
                            </router-link>
                          </div>
                          <div>
                            <router-link
                              to="/create-new-camp"
                              style="border: 1px solid #1275b1"
                            >
                              <span>
                                <img
                                  src="@/assets/soft-ware-img/linkedin-icon.svg"
                                  alt=""
                                />
                              </span>
                              <span> Linked In </span>
                            </router-link>

                            <router-link
                              to="/create-new-camp"
                              style="border: 1px solid #000000"
                            >
                              <span>
                                <img
                                  src="@/assets/soft-ware-img/tikTopkcampaign.svg"
                                  alt=""
                                />
                              </span>
                              <span> Tik Tok </span>
                            </router-link>

                            <router-link
                              to="/create-new-camp"
                              style="border: 1px solid #000000"
                            >
                              <span>
                                <img
                                  src="@/assets/soft-ware-img/x-twitter-icons.svg"
                                  alt=""
                                />
                              </span>
                              <span> X (Twitter) </span>
                            </router-link>
                          </div>
                        </v-card-text>
                        <div class="card-footer">
                          <span>*You can choose multiple platform</span>

                          <button>Add Compaign</button>
                        </div>
                      </v-card>
                    </v-dialog>
                    <button
                      @click="adsReportStatusFunc"
                      class="ads-report-btn d-flex align-center ga-2"
                    >
                      <span class="d-flex">
                        <img
                          src="@/assets/soft-ware-img/ads-report-icon.svg"
                          alt=""
                        />
                      </span>
                      <span>Ads Report</span>
                    </button>
                    <button
                      class="export-btn d-flex align-center ga-2"
                      @click="exportTableToExcel"
                    >
                      <span class="d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 -960 960 960"
                          width="24px"
                          fill="rgba(35, 114, 118, 1)"
                        >
                          <path
                            d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"
                          />
                        </svg>
                      </span>
                      <span>Export File</span>
                    </button>
                  </div>
                </v-col>
                <v-col
                  cols="8"
                  xl="11"
                  md="10"
                  sm="12"
                  class="d-flex my-5 align-center justify-space-between flex-wrap"
                >
                  <div
                    v-if="hideFilter"
                    class="filter-contain d-flex align-center ga-5"
                  >
                    <div class="select-date">
                      <label for="">Date</label>

                      <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model="selectedDate"
                        multiple="range"
                        variant="solo"
                        elevation="0"
                      ></v-date-input>
                    </div>
                    <div class="select-status">
                      <label for="" class="d-block mb-2">Status</label>
                      <v-select
                        v-model="selectedStatus"
                        :items="statusItems"
                        item-text="text"
                        item-value="value"
                        label="Select"
                        persistent-hint
                        return-object
                        single-line
                        variant="solo"
                        elevation="0"
                      ></v-select>
                    </div>
                    <div class="select-status">
                      <label for="" class="d-block mb-2">Platform</label>
                      <v-select
                        v-model="selectedPlatform"
                        :items="platformItems"
                        item-title="platform"
                        item-value="platform"
                        label="Select Platform"
                        persistent-hint
                        return-object
                        single-line
                        variant="solo"
                        elevation="0"
                      ></v-select>
                    </div>
                  </div>
                  <div class="hide-filter">
                    <button
                      @click="toggleFilter"
                      class="d-flex align-center ga-2"
                    >
                      <span
                        ><img
                          src="@/assets/soft-ware-img/filter-icon-performance.svg"
                          alt=""
                      /></span>
                      <span>
                        <span v-if="hideFilter">Hide</span>
                        <span v-else>Show</span> Filters</span
                      >
                    </button>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="table-contain">
                    <table class="w-100" id="data-table" v-if="adsReportStatus">
                      <thead>
                        <tr>
                          <th
                            style="border-radius: 8px 0 0 0"
                            class="d-flex name-contain align-center ga-2"
                          >
                            <div class="checkbox-wrapper-46">
                              <input
                                @change="toggleAllCheckboxes"
                                type="checkbox"
                                id="check-all-name"
                                class="inp-cbx"
                                v-model="checkAll"
                              />
                              <label for="check-all-name" class="cbx"
                                ><span>
                                  <svg
                                    viewBox="0 0 12 10"
                                    height="9px"
                                    width="10px"
                                  >
                                    <polyline
                                      points="1.5 6 4.5 9 10.5 1"
                                    ></polyline></svg></span
                                ><span>Name</span>
                              </label>
                            </div>
                          </th>
                          <th>status</th>
                          <th>Delivery Status</th>
                          <th>Amount Spent</th>
                          <th>Result</th>
                          <th>Cost per Result</th>
                          <th>
                            <span class="d-block">Paid Immpressions</span>
                          </th>
                          <th>Paid eCPM</th>
                          <th>Clicks</th>
                          <th>CPC</th>
                          <th style="border-radius: 0 8px 0 0">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="row in filteredTableData" :key="row.id">
                          <td>
                            <div class="d-flex align-center ga-2">
                              <div class="checkbox-wrapper-46">
                                <input
                                  :checked="row.checked"
                                  type="checkbox"
                                  :id="row.id"
                                  class="inp-cbx"
                                />
                                <label :for="row.id" class="cbx"
                                  ><span>
                                    <svg
                                      viewBox="0 0 12 10"
                                      height="9px"
                                      width="10px"
                                    >
                                      <polyline
                                        points="1.5 6 4.5 9 10.5 1"
                                      ></polyline>
                                    </svg>
                                  </span>
                                  <span
                                    ><img
                                      style="width: 20px"
                                      src="@/assets/soft-ware-img/snapchat-icoon-ccampaign.svg"
                                      alt=""
                                  /></span>
                                  <span v-tooltip:top="row.name">{{
                                    truncateName(row.name, 15)
                                  }}</span>
                                </label>
                              </div>
                            </div>
                          </td>
                          <td class="switch-status">
                            <input
                              class="switch"
                              v-model="campaignStatus"
                              type="checkbox"
                              @change="changeStatus(row.id)"
                              :checked="row.status === 'PAUSED' ? false : true"
                            />
                          </td>
                          <td>
                            <div
                              class="deliviery-status d-flex align-center ga-2"
                            >
                              <span
                                :class="
                                  row.delivery_status &&
                                  row.delivery_status.length > 0
                                    ? 'deleviry-status'
                                    : 'status'
                                "
                              >
                              </span>
                              <span
                                class="d-flex"
                                v-tooltip:top="
                                  row.delivery_status.length === 0
                                    ? 'Not Delivering Compain is Scheduled'
                                    : 'Delivering '
                                "
                              >
                                {{
                                  row.delivery_status.length === 0
                                    ? truncateName(
                                        "Not Delivering Compain is Scheduled",
                                        10
                                      )
                                    : "Delivering"
                                }}
                              </span>
                            </div>
                          </td>
                          <td>
                            <span
                              >{{
                                formatLifetimeSpend(
                                  row.lifetime_spend_cap_micro
                                )
                              }}
                              $</span
                            >
                          </td>
                          <td>
                            <span>
                              {{ row.stats.native_leads }} <br />
                              leads</span
                            >
                          </td>
                          <td>
                            <span
                              >{{
                                (
                                  Math.round(
                                    row.calculations.costPerClick * 100
                                  ) / 100
                                ).toFixed(2)
                              }}$ <br />
                              cost per lead</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                new Intl.NumberFormat("en-US").format(
                                  row.stats.impressions / 100
                                )
                              }}
                            </span>
                          </td>
                          <td>
                            <span
                              >
                              <span
                                >$
                                {{
                                  new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(row.calculations.eCPM)
                                }}</span
                              >
                            </span>
                          </td>
                          <td>
                            <span>{{ row.stats.swipes }}</span>
                          </td>
                          <td>
                            <span>{{    new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(row.calculations.eCPC)  }}$</span>
                          </td>
                          <td>
                            <div class="table-action">
                              <button @click="showAnalisyes(row.id)">
                                <img
                                  src="@/assets/soft-ware-img/see-analisyes.svg"
                                  alt=""
                                />
                              </button>
                              <button>
                                <img
                                  src="@/assets/soft-ware-img/Edit.svg"
                                  alt=""
                                />
                              </button>
                              <button>
                                <img
                                  src="@/assets/soft-ware-img/copy-table-action.svg"
                                  alt=""
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <VerticalBarChart
                      v-else
                      :chartData="data"
                      :chartOptions="options"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import { VDateInput } from "vuetify/labs/VDateInput";
import apiClient from "@/core/buildRequest.js";
import VerticalBarChart from "@/components/VerticalBarChart.vue";
import "@/assets/css/style.css";
import * as XLSX from "xlsx";


export default {
  components: { GlobalNav, SideBar, BreadCrumb, VDateInput, VerticalBarChart },
  data() {
    return {
      campaignNumber: null,
      adsReportStatus: true,
      impressions: null,
      leads: null,
      clicks: null,
      linksData: [
        { name: "Ads", link: "/" },
        { name: "Ads Performance", link: "" },
      ],
      selectedDate: [],
      statusItems: ["PAUSED", "ACTIVE", "Completed"],
      selectPlatform: {
        platform: "Snapchat",
      },
      platformItems: [
        {
          platform: "Snapchat",
        },
        {
          platform: "Meta",
        },
        {
          platform: "Instagram",
        },
        {
          platform: "X",
        },
        {
          platform: "Tik Tok",
        },
      ],
      hideFilter: true,
      ex11: true,
      ex12: true,
      // Filter values
      selectedStatus: null,
      selectedPlatform: null,

      tableData: [],
      checkAll: false,
      dialog: false,
      campaign_id: [],
      campaign_leads: [],
      startTimeCampign: null,
      endTimeCampaign: null,

      data: {
        labels: [
          "Snapchat",
          "Instagram",
          "Tik Tok",
          "Google",
          "X (twitter)",
          "Facebook",
        ],
        datasets: [
          {
            label: "Amount Spent",
            data: [120, 150, 180, 200, 90, 130], // Values for "Amount Spent" per label
            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "Results",
            data: [50, 60, 40, 70, 30, 55], // Values for "Results" per label
            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "Cost per Result",
            data: [10, 15, 12, 8, 20, 14], // Values for "Cost per Result" per label
            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "Impressions",
            data: [1000, 1200, 800, 1500, 700, 1100], // Values for "Impressions" per label
            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "eCPM",
            data: [222.5, 300.0, 202.8, 215.2, 345.5, 245.9], // Values for "eCPM" per label
            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "Clicks",
            data: [500, 600, 450, 700, 300, 550], // Values for "Clicks" per label
            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
          {
            label: "CPC",
            data: [0.5, 0.6, 0.45, 0.7, 0.3, 0.55], // Values for "eCPC" per label
            backgroundColor: "rgba(65, 135, 139, 1)",
            borderColor: "transparent",
            borderWidth: 1,
            borderRadius: 15,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
      campaignStatus:null,
      campaignId:null,
      campaignName:null
    };
  },

  methods: {
    toggleFilter() {
      this.hideFilter = !this.hideFilter;
      this.selectedDate = this.filteredTableData;
      this.selectedStatus = this.filteredTableData;
      this.selectedPlatform = null;
    },
    showAnalisyes(id) {
      this.$router.push(`/ads-performance/${id}`);
    },
    truncateName(name, length) {
      if (!name) return ""; // Handle cases where the name is null or undefined
      return name.length > length ? name.slice(0, length) + "..." : name;
    },
    formatLifetimeSpend(value) {
      if (!value || isNaN(value)) return 0; // Handle undefined or invalid numbers
      return Math.ceil(value / 1_000_000);
    },
    exportTableToExcel() {
      const table = document.getElementById("data-table");
      const workbook = XLSX.utils.table_to_book(table); // Convert the table to a workbook
      const fileName = "table-data.xlsx";
      // Generate the Excel file
      XLSX.writeFile(workbook, fileName);
    },
    toggleAllCheckboxes() {
      console.log(this.checkAll ? "All selected" : "All deselected");
    },
    adsReportStatusFunc() {
      this.adsReportStatus = !this.adsReportStatus;
    },
   async changeStatus(id){
      const requestData ={
        status: this.campaignStatus,
        campaign_id:this.campaignId,
        name:this.campaignName,
        start_time:this.startTimeCampign,
        end_time:this.endTimeCampaign

      }
      await apiClient.post(`/snapchat/update/campaign/${id}`,requestData).then((response)=>{
        console.log(response);
      })     
    }
  },
  watch: {
    checkAll(newValue) {
      // Update all rows when the "select all" checkbox changes
      this.filteredTableData.forEach((row) => {
        row.checked = newValue;
      });
    },
  },
  async mounted() {
    this.leads = 0;
    this.campaignNumber = 0;
    this.amountSpent = 0;
    this.impressions = 0;
    const requestData = {
      adAccountId: "3f679f50-3aa2-4684-a217-e177d84e1742",
      matrices: ["impressions", "swipes", "native_leads", "spend"],
    };
    // Fetch campaign data
    await apiClient
      .post("/snapchat/camapign/states", requestData)
      .then((response) => {
        if (response.data && response.data.data) {
          this.tableData = response.data.data.performance.campaigns;
          console.log("Table Data:", this.tableData);

          const summition = response.data.data.performance.summations;
          console.log("ssss" + summition);
          this.campaignNumber = summition.campaign_sum;
          this.leads = summition.leadsSum;
          this.amountSpent = new Intl.NumberFormat("en-US").format(
            summition.spendSum / 100
          );
          this.impressions = new Intl.NumberFormat("en-US").format(
            summition.impressionSum / 100
          );
          this.clicks = summition.swipesSum;
          this.startTimeCampign = this.tableData.start_time;
          this.endTimeCampaign = this.tableData.end_time;
          this.campaignId = this.tableData.id;
          this.campaignName = this.tableData.name;
        } else {
          console.error("Response data is missing or empty.");
          this.tableData = [];
        }
      })
      .catch((error) =>
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        )
      );
  },
  computed: {
    filteredTableData() {
      return this.tableData.filter((row) => {
        const isStatusMatch =
          !this.selectedStatus || row.status === this.selectedStatus;

        const isDateInRange =
          !this.selectedDate.length ||
          (new Date(row.start_time) >= new Date(this.selectedDate[0]) &&
            new Date(row.end_time) <= new Date(this.selectedDate[1]));

        return isStatusMatch && isDateInRange;
      });
    },
  },
};
</script>
