<template>
  <div>
    <ul>
      <li v-for="(link, index) in linksData" :key="index">
        <router-link :to="link.link" :class="{ 'last-link': isLastLink(index) }"
            :style="isLastLink(index) ? 'color: rgba(35, 114, 118, 1);font-weight: 500;cursor: no-drop' : ''" >
          <span v-if="link.name" style="margin-right: 15px;" >
            {{ link.name }}
          </span>
          <span v-if="!isLastLink(index)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill="rgba(179, 179, 179, 1)"
              viewBox="0 0 320 512"
            >
              <path
                d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
              />
            </svg>
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    linksData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      
    };
  },
  methods: {
    isLastLink(index) {
      return index === this.linksData.length - 1;
    },
  },
};
</script>

<style scoped>
ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 15px;
}
ul li a{
    text-decoration: none;
    color: rgba(134, 134, 134, 1);
    display: flex;
    align-items: center;
    font-size: 16px;
}
</style>
