import { defineStore } from "pinia";
import apiClient from "@/core/buildRequest"; // Use apiClient instead of axios
import { toast } from "vue3-toastify";
import router from "@/router/index.js";
import { v4 as uuidv4 } from "uuid";

function getDeviceId() {
  let deviceId = localStorage.getItem("deviceId");
  if (!deviceId) {
    deviceId = uuidv4(); // Generate a new UUID
    localStorage.setItem("deviceId", deviceId); // Store it in localStorage
  }
  return deviceId;
}

export const loginForm = defineStore("logIn", {
  state: () => ({
    userInfo: {
      email: null,
      password: null,
    },
    validateText: [],
    loading: false,
  }),
  actions: {
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    checkAuth() {
      const userToken = localStorage.getItem("user_token");
      if (userToken) {
        router.push({ name: "HomePage" });
      }
    },
    async login() {
      this.startLoading();
      try {
        if (!this.userInfo.email) {
          if (!this.validateText.includes("Email is required")) {
            this.validateText.push("Email is required");
            this.stopLoading();
          }
        } else {
          const emailIndex = this.validateText.indexOf("Email is required");
          if (emailIndex > -1) {
            this.validateText.splice(emailIndex, 1);
            this.stopLoading();
          }
        }

        if (!this.userInfo.password) {
          if (!this.validateText.includes("Password is required")) {
            this.validateText.push("Password is required");
            this.stopLoading();
          }
        } else {
          const passwordIndex = this.validateText.indexOf(
            "Password is required"
          );
          if (passwordIndex > -1) {
            this.validateText.splice(passwordIndex, 1);
            this.stopLoading();
          }
        }

        if (this.validateText.length > 0) {
          console.log(this.validateText);
          this.stopLoading();

          return;
        }
        const deviceId = getDeviceId();
        const response = await apiClient.post("/sign-in", {
          email: this.userInfo.email,
          password: this.userInfo.password,
          device_id: deviceId,
          device_type: "web",
        });

        localStorage.setItem("user", JSON.stringify(response.data));
        console.log(response.data.data.token);
        const userToken = response.data.data.token;
        localStorage.setItem("user_token", userToken);
        toast(`${response.data.msg}`, {
          type: "success",
        });
        router.push({ name: "HomePage" });
      } catch (error) {
        this.stopLoading();

        // Error handling is now managed globally in buildRequest.js
      }
    },
  },
});
