import { defineStore } from "pinia";

export const publishPost = defineStore("publishPost", {
    state: () => ({
        storyContent: false, 
        mediaFilsPost:[],
        facebookPostMedia:[],
        instagramPostMedia:[],
        tikTokPostMedia:[],
        snapChatPostMedia:[],  
        facebookStoryMedia:[],  
        facebookReelMedia:[],
        instagramStoryMedia:[],
        instagramReelMedia:[],
        tikTokStoryMedia:[],
        snapchatStoryMedia:[],
        snapChatReelMedia:[],
        allMediaFils:[]
      }),
      
  actions: {
    updateStoryContant() {
      this.storyContant = !this.storyContant;
      console.log(this.storyContant);
    },
    sendPostDate(){
      console.log()
    }
  },
});
