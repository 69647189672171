import { createRouter, createWebHistory } from "vue-router";

// import UserPage from "@/views/porofile/UserPage";
// import UploadImage from "@/views/media/UploadImage";
import TakeTime from "@/views/calender/TakeTime";
// the project content
import AdsPerformance from "@/views/AdsPerformancePage.vue";
import InsightsPage from "@/views/InsightsPage.vue";
import PublishPost from "@/views/PublishPost.vue";

import authMiddleware from "../middleware/auth";
import snapchatRoutes from "@/router/snapchat.js";
import generalRoutes from "@/router/general.js";
import modulesRoutes from "@/router/modules.js";
const routes = [
  {
    path: "/publish-post",
    name: "PublishPost",
    component: PublishPost,
    meta: {
      title: "Publish Post",
      description: "",
      auth: "auth",
    },
  },
  {
    path: "/ads-performance",
    name: "AdsPerformance",
    component: AdsPerformance,
    meta: {
      title: "Ads Performance",
      description: "",
    },
  },
  {
    path: "/calender/bookDate",
    name: "TakeTime",
    component: TakeTime,
    meta: {
      title: "Book Date",
    },
  },
  {
    path: "/ads-performance/:id",
    name: "InsightsPage",
    component: InsightsPage,
    meta: {
      title: "Insights",
    },
  },
  ...snapchatRoutes,
  ...generalRoutes,
  ...modulesRoutes,
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (window.location.protocol === "http:") {
    window.location.href = window.location.href.replace("http://", "https://");
  } else {
    next();
  }

  authMiddleware(to, from, next);

  document.title = to.meta.title;
  next();
});

export default router;
