<template>
  <v-dialog
    v-model="create_lookalike_audiance_dialog"
    max-width="55%"
    persistent
  >
    <v-card class="pa-5 radius-10">
      <div>
        <span>Create New Audience</span>
      </div>

      <v-spacer></v-spacer>
      <div>
        <span class="small-text muted-text"
          >Create New Customer List Audience</span
        >
      </div>
      <div class="body mt-3">
        <div class="filed mt-3">
          <span class="title">Your audience is located in:</span>
          <LookalikeLocations />
        </div>
        <div class="filed mt-3">
          <span class="title">Lookalike Type</span>
          <v-select
            v-model="lookalike_type"
            class="v-select-style"
            :items="['Reach', 'Balance', 'Similarity']"
          ></v-select>
        </div>
        <div class="filed mt-3">
          <span class="title">Audience Name</span>
          <input
            type="text"
            v-model="audiance_name"
            placeholder="Enter Your Audience Name"
          />
          <div class="warning">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#444546"
              xmlns="http://www.w3.org/2000/svg"
              class="warning-svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
              ></path>
            </svg>
            <p class="small-text muted-text">
              The audience must be given a name
            </p>
          </div>
        </div>
        <div class="filed mt-3">
          <span class="title">Description</span>
          <input v-model="description" type="text" />
        </div>
      </div>
      <div class="mt-5 d-flex justify-end align-center ga-3">
        <a @click="create_lookalike_audiance_dialog = false" class="cancel-btn"
          >Cancel</a
        >
        <v-btn class="upload-button" :readonly="!upload_ready">Upload</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import LookalikeLocations from "./LookalikeLocations.vue";
export default {
  components: { LookalikeLocations },
  name: "CreateNewCustomerListAudience",
  data() {
    return {
      create_lookalike_audiance_dialog: false,
      audiance_name: "",
      lookalike_type: "",
      description: "",
      upload_ready: false,
    };
  },
  methods: {
    openDialog() {
      this.create_lookalike_audiance_dialog = true;
    },
    closeDialog() {
      this.create_lookalike_audiance_dialog = false;
    },
  },
};
</script>

<style scoped>
.body-header {
  background: #f7f9fb;
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.blue-link {
  color: #3bf;
  text-decoration: none;
}
.blue-link:hover {
  color: #0894fa;
}
input {
  background-color: #f9f9fa;
  height: 38px;
}
.warning {
  display: flex;
  gap: 5px;
}
.warning-svg {
  color: rgb(8, 148, 250);
  fill: rgb(8, 148, 250);
  flex-shrink: 0;
}
.filed .title {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.cancel-btn {
  color: black;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  padding-bottom: 5px;
}
.cancel-btn:hover {
  color: blue;
}
.cancel-btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: blue;
  transition: width 0.3s ease;
}
.cancel-btn:hover::after {
  width: 100%;
}
.upload-button {
  align-items: center;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 20px;
  background: white;
  box-shadow: 0 0 0 1px #d1d3d5;
  color: #545557;
}
.upload-button.v-btn--readonly {
  background: #e1e3e5;
  color: #848688;
}
</style>
