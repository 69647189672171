<template>
  <v-card
    max-width="100%"
    class="pa-5 rounded-lg promote-places-options"
    elevation="1"
  >
    <div class="card-header mb-3 d-flex align-center justify-space-between">
      <div class="left-side">
        <label for="" class="d-block mb-1">Promote Places</label>
        <p>Promote your Places to snapchatters</p>
      </div>
      <div class="right-side">
        <switch-toogle
          :checked="promotePlaces"
          @tooglePromotionOptions="showPromotePlaces"
        ></switch-toogle>
      </div>
    </div>
    <div
      class="card-body create-new-camp-nav custome-radio-btn"
      v-if="promotePlaces"
    >
      <label for="" class="d-block section-title mb-2">Promotion Options</label>
      <div class="options-group radio-button">
        <input
          name="promotion-options"
          id="promotion-options-1"
          class="radio-button__input"
          v-model="promoteSinglePlace"
          checked
          type="radio"
        />
        <label for="promotion-options-1" class="radio-button__label">
          <span class="radio-button__custom"></span>
          <p class="">
            <span class="d-block">Promote a single place</span>
            <span class="d-block">Run promotion for a single place</span>
          </p>
        </label>
      </div>
      <div class="options-group radio-button disabled-options">
        <input
          name="promotion-options"
          id="promotion-options-2"
          class="radio-button__input"
          type="radio"
          disabled
        />
        <label for="promotion-options-2" class="radio-button__label">
          <span class="radio-button__custom"></span>
          <p class="">
            <span class="d-block">Promote a single place</span>
            <span class="d-block">Run promotion for a single place</span>
          </p>
        </label>
      </div>
    </div>
  </v-card>
</template>

<script>
// import InputLabel from "./Label&input/InputLabel.vue";
import SwitchToogle from "./Label&input/SwitchToogle.vue";
export default {
  components: { SwitchToogle },
  data() {
    return {
      promotePlaces: false,
    };
  },
  mounted() {
    console.log(this.promotePlaces);
    const storedValue = localStorage.getItem("promote-places");
    this.promotePlaces = storedValue === "true";
  },
  methods: {
    showPromotePlaces() {
      console.log(this.promotePlaces);
      this.promotePlaces = !this.promotePlaces;
      localStorage.setItem("promote-places", this.promotePlaces);
    },
  },
};
</script>
