<template>
  <MbscEventcalendar
    :clickToCreate="false"
    :dragToCreate="false"
    :dragToMove="false"
    :dragToResize="false"
    :eventDelete="false"
    :view="myView"
    :data="myEvents"
    @event-click="handleEventClick"
    @cell-click="handleDayClick"
  />
  <MbscToast
    :message="toastMessage"
    :isOpen="isToastOpen"
    @close="handleToastClose"
  />

  <!-- Modal for adding or editing event -->
  <div v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <h3>{{ isEditing ? "Edit Event" : "Add Event" }}</h3>
      <input v-model="newEventTitle" placeholder="Event title" />
      <input type="time" v-model="newEventStartTime" placeholder="Start time" />
      <input type="time" v-model="newEventEndTime" placeholder="End time" />
      <button @click="isEditing ? saveEditedEvent() : appendEvent()">
        {{ isEditing ? "Save Changes" : "Add Event" }}
      </button>
      <button @click="isModalOpen = false">Cancel</button>
    </div>
  </div>
</template>

<script setup>
import "@mobiscroll/vue/dist/css/mobiscroll.min.css";
import {
  MbscEventcalendar,
  MbscToast,
  setOptions,
  localeAr,
} from "@mobiscroll/vue";
import { onMounted, ref } from "vue";

setOptions({
  locale: localeAr,
  theme: "ios",
  themeVariant: "light",
});

const myEvents = ref([]);
const toastMessage = ref("");
const isToastOpen = ref(false);
const isModalOpen = ref(false);
const newEventTitle = ref("");
const newEventStartTime = ref("");
const newEventEndTime = ref("");
const selectedDate = ref(null);
const isEditing = ref(false);
const editingEventIndex = ref(null);

function handleToastClose() {
  isToastOpen.value = false;
}

const myView = {
  calendar: { labels: true },
};
function handleEventClick(args) {
  if (args.event) {
    selectedDate.value = args.event.start || new Date();
    newEventTitle.value = args.event.title || "";
    newEventStartTime.value = args.event.start
      ? formatTime(new Date(args.event.start))
      : "";
    newEventEndTime.value = args.event.end
      ? formatTime(new Date(args.event.end))
      : "";

    // Convert event start and end to Date objects before comparing
    editingEventIndex.value = myEvents.value.findIndex((event) => {
      const eventStart = new Date(event.start);
      const eventEnd = new Date(event.end);
      const clickedStart = new Date(args.event.start);
      const clickedEnd = new Date(args.event.end);
      return (
        eventStart.getTime() === clickedStart.getTime() &&
        eventEnd.getTime() === clickedEnd.getTime() &&
        event.title === args.event.title
      );
    });

    // Ensure the index is valid before enabling editing
    if (editingEventIndex.value !== -1) {
      isEditing.value = true;
      isModalOpen.value = true;
    } else {
      // Optionally show a toast message or alert if the event wasn't found
      toastMessage.value = "Event could not be found for editing.";
      isToastOpen.value = true;
    }
  }
}

function handleDayClick(args) {
  selectedDate.value = args.date;
  isEditing.value = false;
  newEventTitle.value = "";
  newEventStartTime.value = "";
  newEventEndTime.value = "";
  isModalOpen.value = true;
}
function appendEvent() {
  if (newEventTitle.value && newEventStartTime.value && newEventEndTime.value) {
    const startDate = new Date(selectedDate.value);
    const endDate = new Date(selectedDate.value);
    const [startHour, startMinute] = newEventStartTime.value.split(":");
    const [endHour, endMinute] = newEventEndTime.value.split(":");

    startDate.setHours(startHour, startMinute);
    endDate.setHours(endHour, endMinute);
    const newEvent = {
      start: startDate,
      end: endDate,
      title: newEventTitle.value,
    };
    myEvents.value = [...myEvents.value, newEvent];

    // Save the updated events list to local storage
    localStorage.setItem("myEvents", JSON.stringify(myEvents.value));

    newEventTitle.value = "";
    newEventStartTime.value = "";
    newEventEndTime.value = "";
    isModalOpen.value = false;
  }
}
function saveEditedEvent() {
  console.log("SDS");
  if (editingEventIndex.value !== null && newEventTitle.value) {
    const startDate = new Date(selectedDate.value);
    const endDate = new Date(selectedDate.value);
    const [startHour, startMinute] = newEventStartTime.value.split(":");
    const [endHour, endMinute] = newEventEndTime.value.split(":");

    startDate.setHours(startHour, startMinute);
    endDate.setHours(endHour, endMinute);
    const updatedEvent = {
      start: startDate,
      end: endDate,
      title: newEventTitle.value,
    };

    // Update the event in the events list
    myEvents.value = [
      ...myEvents.value.slice(0, editingEventIndex.value),
      updatedEvent,
      ...myEvents.value.slice(editingEventIndex.value + 1),
    ];

    // Save the updated events list to local storage
    localStorage.setItem("myEvents", JSON.stringify(myEvents.value));

    isModalOpen.value = false;
    isEditing.value = false;
    editingEventIndex.value = null;
  }
}
function formatTime(date) {
  if (!(date instanceof Date) || isNaN(date.getTime())) return "";
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
}

onMounted(() => {
  const storedEvents = localStorage.getItem("myEvents");
  if (storedEvents) {
    myEvents.value = JSON.parse(storedEvents);
  }
});
</script>

<style scoped>
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 897;
}
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
}
button {
  margin: 10px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
</style>
