<template>
  <div class="delivery ad-set-details-card mb-6 fs-14">
    <h6 class="page-header-title mb-3">Delivery</h6>

    <div
      class="bg-white"
      style="
        padding: 19px;
        border-radius: 15px;
        box-shadow: 0px 2px 1px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
          0px 1px 1px 0px
            var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
          0px 1px 3px 0px
            var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12)) !important;
      "
    >
      <span>Third Party Tagging</span>
      <div>
        <label class="snap-checkbox">
          <input v-model="double_verify" type="checkbox" />
          <span class="snap-checkmark"></span>DoubleVerify
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      double_verify: true,
    };
  },
  methods: {},
  watch: {},
  mounted() {},
};
</script>
