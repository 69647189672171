<template>
  <!-- Placements -->
  <div class="placements ad-set-details-card mb-6 fs-14">
    <h6 class="page-header-title mb-3">Placements</h6>
    <v-card
      max-width="100%"
      class="pa-5 mb-5 rounded-lg"
      style="overflow: visible"
      elevation="1"
    >
      <div class="card-body">
        <div class="placements tree-select">
          <!-- automatic_placement  -->
          <div class="radio-button">
            <input
              v-model="placement_type"
              value="automatic_placement"
              name="placement_type"
              id="automatic_placement"
              class="radio-button__input"
              type="radio"
            />
            <label for="automatic_placement" class="radio-button__label">
              <span class="radio-button__custom"></span>
              <span class="d-block">Automatic Placement (Recommended)</span>
              <span class="d-block small-text">
                Run your ads across all of Snapchat. This gives more flexibility
                to improve the results of your campaign and maximize your reach.
              </span>
            </label>
          </div>
          <!-- edit_placement  -->
          <div class="radio-button">
            <input
              v-model="placement_type"
              value="edit_placement"
              name="placement_type"
              id="edit_placement"
              class="radio-button__input"
              type="radio"
            />
            <label for="edit_placement" class="radio-button__label">
              <span class="radio-button__custom"></span>
              <span class="d-block">Edit Placement</span>
            </label>
          </div>
        </div>
        <!-- edit_placement_options  -->
        <div
          v-if="placement_type == 'edit_placement'"
          class="edit-placement-selections"
        >
          <div class="edit-placement-selections-tree tree-select ml-7">
            <!-- content  -->
            <div class="radio-button">
              <input
                v-model="edit_placement_type"
                value="content"
                name="edit_placement_content"
                id="edit_placement_content"
                class="radio-button__input"
                type="radio"
              />
              <label for="edit_placement_content" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">content</span>
              </label>
              <!-- content options  -->
              <div v-if="edit_placement_type == 'content'" class="fs-14 ml-7">
                <!-- between content  -->
                <div class="">
                  <div class="d-flex align-center justify-start ga-2">
                    <label class="snap-checkbox">
                      <input v-model="between_content" type="checkbox" />
                      <span class="snap-checkmark"></span>Between content
                      <span class="text-muted small-text"
                        >(Ads watched between stories and professional
                        content)</span
                      >
                    </label>
                  </div>
                  <!-- between options  -->
                  <div class="between-options ml-7">
                    <div class="d-flex align-center justify-start ga-2">
                      <label class="snap-checkbox">
                        <input
                          v-model="between_content_user_stories"
                          type="checkbox"
                        />
                        <span class="snap-checkmark"></span>User Stories
                      </label>
                    </div>
                    <div class="d-flex align-center justify-start ga-2">
                      <label class="snap-checkbox">
                        <input
                          v-model="
                            between_content_publisher_and_creator_stories
                          "
                          type="checkbox"
                        />
                        <span class="snap-checkmark"></span>Publisher & Creator
                        Stories
                      </label>
                    </div>
                    <div class="d-flex align-center justify-start ga-2">
                      <label class="snap-checkbox">
                        <input
                          v-model="between_content_spotlight"
                          type="checkbox"
                        />
                        <span class="snap-checkmark"></span>Spotlight
                      </label>
                    </div>
                  </div>
                </div>
                <!-- within content -->
                <div class="">
                  <div class="d-flex align-center justify-start ga-2">
                    <label class="snap-checkbox">
                      <input v-model="within_content" type="checkbox" />
                      <span class="snap-checkmark"></span>Within content
                      <span class="text-muted small-text"
                        >(Ads watched within professional, creator & curated
                        content)</span
                      >
                    </label>
                  </div>
                  <!-- within options  -->
                  <div class="within-options ml-7">
                    <!-- within publisher stories  -->
                    <div class="publisher-stories">
                      <div class="d-flex align-center justify-start ga-2">
                        <label class="snap-checkbox">
                          <input
                            v-model="within_content_publisher_stories"
                            type="checkbox"
                          />
                          <span class="snap-checkmark"></span>Publisher Stories
                        </label>
                      </div>
                      <div class="ml-7">
                        <div class="fs-14">Categories</div>
                        <div class="d-1-3 ga-2">
                          <v-select
                            variant="solo"
                            class="v-select-style"
                            v-model="
                              publisher_stories_selected_categories_types
                            "
                            :items="publisherStoriesCategoriesTypes"
                          ></v-select>
                          <v-select
                            variant="solo"
                            class="v-select-style"
                            v-model="publisher_stories_selected_categories"
                            :items="publisherStoriesCategories"
                          ></v-select>
                        </div>
                      </div>
                    </div>
                    <!-- within creator stories  -->
                    <div class="creator-stories">
                      <div class="d-flex align-center justify-start ga-2">
                        <label class="snap-checkbox">
                          <input
                            v-model="within_content_creator_stories"
                            type="checkbox"
                          />
                          <span class="snap-checkmark"></span>Creator Stories
                        </label>
                      </div>
                      <div class="ml-7">
                        <div class="fs-14">Categories</div>
                        <div class="d-1-3 ga-2">
                          <v-select
                            variant="solo"
                            class="v-select-style"
                            v-model="creator_stories_selected_categories_types"
                            :items="creatorStoriesCategoriesTypes"
                          ></v-select>
                          <v-select
                            variant="solo"
                            class="v-select-style"
                            v-model="creator_stories_selected_categories"
                            :items="creatorStoriesCategories"
                          ></v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- within content -->
                <div class="">
                  <div class="d-flex align-center justify-start ga-2">
                    <label class="snap-checkbox">
                      <input v-model="discover_feed" type="checkbox" />
                      <span class="snap-checkmark"></span>Discover Feed
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- camera  -->
            <div
              v-if="
                (conversion_location === 'WEB' &&
                  (selected_objective === 'Sales' ||
                    selected_objective === 'Traffic')) ||
                selected_objective === 'App Promotion'
              "
              class="radio-button"
            >
              <input
                v-model="edit_placement_type"
                value="camera"
                name="edit_placement_camera"
                id="edit_placement_camera"
                class="radio-button__input"
                type="radio"
              />
              <label for="edit_placement_camera" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">Camera</span>
              </label>
            </div>
            <div v-if="edit_placement_type == 'camera'" class="ml-7">
              <div class="d-flex align-center justify-start ga-2">
                <label class="snap-checkbox">
                  <input v-model="camera_ar_lens" type="checkbox" />
                  <span class="snap-checkmark"></span>AR lens
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="more-options-parent">
        <button type="button" class="more-options mb-2" @click="moreOptionFun">
          <p v-if="!moreOptionsBtn">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#0894fa"
                width="13"
                height="13"
                viewBox="0 0 512 512"
              >
                <path
                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                />
              </svg>
            </span>
            <span> More </span>
          </p>
          <p v-else>
            <span>
              <svg
                fill="#0894fa"
                width="13"
                height="13"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                />
              </svg>
            </span>
            <span> Less </span>
          </p>
        </button>
        <div
          v-show="moreOptions"
          class="more-options-contenant d-none px-1 mt-4"
          ref="moreOptionsContenant"
        >
          <span class="fs-14">Brand Safety Filter</span>
          <div class="select-with-caption mt-3">
            <div
              :class="
                'option ' +
                brand_safety_filter_active_fully +
                ' tree-select mb-3'
              "
            >
              <div class="radio-button">
                <input
                  v-model="brand_safety_filter"
                  value="full_inventory"
                  name="full_inventory"
                  id="full_inventory"
                  class="radio-button__input"
                  type="radio"
                />
                <label for="full_inventory" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <span class="d-block">Full Inventory</span>
                  <span class="d-block small-text">
                    Show ads near any Snapchat content to maximize reach.
                    Content still adheres to Snap's Community Guidelines and
                    content moderation rules.
                  </span>
                </label>
              </div>
            </div>
            <div
              :class="
                'option ' + brand_safety_filter_active_limited + ' tree-select'
              "
            >
              <div class="radio-button">
                <input
                  :disabled="edit_placement_type == 'camera'"
                  v-model="brand_safety_filter"
                  value="limited_inventory"
                  name="limited_inventory"
                  id="limited_inventory"
                  class="radio-button__input"
                  type="radio"
                />
                <label for="limited_inventory" class="radio-button__label">
                  <span class="radio-button__custom"></span>
                  <span class="d-block">Limited Inventory</span>
                  <span class="d-block small-text">
                    Show ads near content that has passed additional moderation
                    filters. This may decrease reach and increase costs. Only
                    available for Creator Stories right now.
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      placement_type:
        localStorage.getItem("placement_type") || "automatic_placement",
      edit_placement_type:
        localStorage.getItem("edit_placement_type") || "content",
      between_content: true,
      between_content_user_stories: true,
      between_content_publisher_and_creator_stories: true,
      between_content_spotlight: true,
      within_content: true,
      within_content_publisher_stories: true,
      publisher_stories_selected_categories_types: localStorage.getItem(
        "publisher_stories_selected_categories_types"
      ),
      publisherStoriesCategoriesTypes: ["include", "exclude"],
      publisher_stories_selected_categories:
        localStorage.getItem("publisher_stories_selected_categories") || "",
      publisherStoriesCategories: [
        "Beauty & Fashion",
        "Entertainment",
        "Food",
        "General Lifestyle",
        "Men's Lifestyle",
        "News",
        "Science & Technology",
        "Sports",
        "Women's Lifestyle",
        "Young & Bold",
      ],
      within_content_creator_stories: true,
      creator_stories_selected_categories_types: localStorage.getItem(
        "creator_stories_selected_categories_types"
      ),
      creatorStoriesCategoriesTypes: ["include", "exclude"],
      creator_stories_selected_categories:
        localStorage.getItem("creator_stories_selected_categories") || "",
      creatorStoriesCategories: [
        "Lifestyle",
        "Sports",
        "Music",
        "Others",
        "Comedy",
        "Entertainment",
        "Family",
        "News and current events",
        "Technology",
        "Fashion",
        "Travel",
      ],
      moreOptionsBtn: false,
      brand_safety_filter_active_fully: "active",
      brand_safety_filter:
        localStorage.getItem("brand_safety_filter") || "full_inventory",
      brand_safety_filter_active_limited: "",
      moreOptions: true,
      camera_ar_lens: true,
      discover_feed: true,
      conversion_location: localStorage.getItem("direct_traffic_location"),
      selected_objective: localStorage.getItem("selectedOption"),
    };
  },
  methods: {
    moreOptionFun() {
      this.moreOptionsBtn = !this.moreOptionsBtn;
      if (
        this.$refs.moreOptionsContenant.classList.contains("hide-more-option")
      ) {
        this.$refs.moreOptionsContenant.classList.add("d-none");
      } else if (
        !this.$refs.moreOptionsContenant.classList.contains("hide-more-option")
      ) {
        this.$refs.moreOptionsContenant.classList.toggle("d-none");
      }
    },
  },
  computed: {},
  watch: {
    placement_type(value) {
      localStorage.setItem("placement_type", value);
    },
    edit_placement_type(val) {
      localStorage.setItem("edit_placement_type", val);
    },
    between_content_user_stories(val) {
      localStorage.setItem("between_content_user_stories", val);
    },
    between_content_publisher_and_creator_stories(val) {
      localStorage.setItem(
        "between_content_publisher_and_creator_stories",
        val
      );
    },
    between_content_spotlight(val) {
      localStorage.setItem("between_content_spotlight", val);
    },
    within_content(val) {
      localStorage.setItem("within_content", val);
    },
    within_content_publisher_stories(val) {
      localStorage.setItem("within_content_publisher_stories", val);
    },
    publisher_stories_selected_categories_types(val) {
      localStorage.setItem("publisher_stories_selected_categories_types", val);
    },
    publisher_stories_selected_categories(val) {
      localStorage.setItem("publisher_stories_selected_categories", val);
    },
    within_content_creator_stories(val) {
      localStorage.setItem("within_content_creator_stories", val);
    },
    creator_stories_selected_categories_types(val) {
      localStorage.setItem("creator_stories_selected_categories_types", val);
    },
    creator_stories_selected_categories(val) {
      localStorage.setItem("creator_stories_selected_categories", val);
    },
    brand_safety_filter(val) {
      if (this.brand_safety_filter == "full_inventory") {
        this.brand_safety_filter_active_fully = "active";
        this.brand_safety_filter_active_limited = "";
      } else {
        this.brand_safety_filter_active_fully = "";
        this.brand_safety_filter_active_limited = "active";
      }
      localStorage.setItem("brand_safety_filter", val);
    },
    camera_ar_lens(val) {
      localStorage.setItem("camera_ar_lens", val);
    },
    discover_feed(val) {
      localStorage.setItem("discover_feed", val);
    },
  },
  mounted() {
    this.between_content_user_stories =
      localStorage.getItem("between_content_user_stories") === "true";
    this.between_content_publisher_and_creator_stories =
      localStorage.getItem("between_content_publisher_and_creator_stories") ===
      "true";
    this.between_content_spotlight =
      localStorage.getItem("between_content_spotlight") === "true";
    this.within_content = localStorage.getItem("within_content") === "true";
    this.within_content_publisher_stories =
      localStorage.getItem("within_content_publisher_stories") === "true";
    this.within_content_creator_stories =
      localStorage.getItem("within_content_creator_stories") === "true";
    this.camera_ar_lens = localStorage.getItem("camera_ar_lens") === "true";
    this.discover_feed = localStorage.getItem("discover_feed") === "true";
  },
};
</script>
