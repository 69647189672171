<template>
  <!-- Ad Set Details -->
  <div v-if="promotePlaces" class="ad-set-details-card place-to-promote mb-6">
    <h6 class="page-header-title mb-3">What Place do you want to promote?</h6>
    <v-card max-width="100%" class="pa-5 mb-5 rounded-lg" elevation="1">
      <div class="card-header">
        <div class="name-zipcode-contain">
          <input
            type="text"
            v-model="placeName"
            placeholder="Place Name"
            class="form-control"
          />
          <span>Near</span>
          <input
            type="text"
            v-model="zipCode"
            placeholder="Zipcode or City"
            name=""
            id=""
          />
          <button
            :class="{ disabled: !isButtonEnabled }"
            :disabled="!isButtonEnabled"
            @click="searchPlace"
          >
            Search
          </button>
        </div>
        <div class="promote-places-alert" v-if="alertNameZipCode">
          <p class="d-flex align-center ga-3">
            <span class="d-flex">
              <img src="@/assets/snapchatImage/alert-icon.svg" alt="" />
            </span>
            Please enter a Place name and Zip to search.
          </p>
        </div>
        <div class="result-place-contain" v-else-if="result">
          <p>Result: {{ result }}</p>
        </div>
      </div>
    </v-card>
  </div>
  <!-- Ad Set Details -->
  <div class="ad-set-details-card mb-6">
    <h6 class="page-header-title mb-3">Ad Set Details</h6>
    <v-card max-width="100%" class="pa-5 mb-5 rounded-lg" elevation="1">
      <div class="card-header">
        <label for="" class="d-block">Ad Set Name</label>

        <input
          type="text"
          :class="
            !isValidate.ad_set_details
              ? 'required_input mb-3 rounded custom-input'
              : 'mb-3 rounded custom-input'
          "
          v-model="ad_set_details"
          placeholder="Ad Set Name"
        />
        <div v-if="selectedOption == 'Traffic'" class="">
          <div class="tree-select">
            <div class="conversion-location">
              <span class="conversion-location-title">
                Conversion Location
              </span>
              <div class="conversion-location-text">
                hoose where you want your ad to direct traffic. You'll add more
                details about the destination later on.
              </div>
            </div>

            <div class="radio-button">
              <input
                v-model="directTrafficLocation"
                value="WEB"
                name="ac-conversion-location"
                id="WEB"
                class="radio-button__input"
                type="radio"
              />
              <label for="WEB" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">Web</span>
                <span class="d-block small-text">
                  Drive traffic to your website.
                </span>
              </label>
            </div>
            <div class="radio-button">
              <input
                v-model="directTrafficLocation"
                value="APP"
                name="ac-conversion-location"
                id="APP"
                class="radio-button__input"
                type="radio"
              />
              <label for="APP" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">APP</span>
                <span class="d-block small-text">
                  Drive traffic to your app.
                </span>
              </label>
            </div>
            <div class="radio-button">
              <input
                v-model="directTrafficLocation"
                value="MY_PUBLIC_PROFILE"
                name="ac-conversion-location"
                id="MY_PUBLIC_PROFILE"
                class="radio-button__input"
                type="radio"
              />
              <label for="MY_PUBLIC_PROFILE" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">my Public Profile</span>
                <span class="d-block small-text">
                  Get Snapchatters to visit your public profile.
                </span>
              </label>
            </div>
            <div class="radio-button">
              <input
                v-model="directTrafficLocation"
                value="CALL"
                name="ac-conversion-location"
                id="CALL"
                class="radio-button__input"
                type="radio"
              />
              <label for="CALL" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">Call</span>
                <span class="d-block small-text"
                  >Get Snapchatters to call your business.
                </span>
              </label>
            </div>
            <div class="radio-button">
              <input
                v-model="directTrafficLocation"
                value="TEXT"
                name="ac-conversion-location"
                id="TEXT"
                class="radio-button__input"
                type="radio"
              />
              <label for="TEXT" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">Text</span>
                <span class="d-block small-text"
                  >Get Snapchatters to text your business.
                </span>
              </label>
            </div>
          </div>
        </div>
        <div v-if="selectedOption == 'Sales'" class="">
          <div class="tree-select">
            <div class="conversion-location">
              <span class="conversion-location-title">
                Conversion Location
              </span>
              <div class="conversion-location-text">
                hoose where you want your ad to direct traffic. You'll add more
                details about the destination later on.
              </div>
            </div>

            <div class="radio-button">
              <input
                v-model="directTrafficLocation"
                value="WEB"
                name="ac-conversion-location"
                id="WEB"
                class="radio-button__input"
                type="radio"
              />
              <label for="WEB" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">Web</span>
                <span class="d-block small-text">
                  Drive traffic to your website.
                </span>
              </label>
            </div>
            <div class="radio-button">
              <input
                v-model="directTrafficLocation"
                value="APP"
                name="ac-conversion-location"
                id="APP"
                class="radio-button__input"
                type="radio"
              />
              <label for="APP" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">APP</span>
                <span class="d-block small-text">
                  Drive traffic to your app.
                </span>
              </label>
            </div>
          </div>
        </div>
        <div v-if="selectedOption == 'Leads'" class="">
          <div class="tree-select">
            <div class="conversion-location">
              <span class="conversion-location-title">
                Conversion Location
              </span>
              <div class="conversion-location-text">
                hoose where you want your ad to direct traffic. You'll add more
                details about the destination later on.
              </div>
            </div>
            <div class="radio-button">
              <input
                v-model="directTrafficLocation"
                value="LEAD-FORM"
                name="ac-conversion-location"
                id="LEAD-FORM"
                class="radio-button__input"
                type="radio"
              />
              <label for="LEAD-FORM" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">LEAD FORM</span>
                <span class="d-block small-text">
                  Generate leads from your lead form.
                </span>
              </label>
            </div>
            <div class="radio-button">
              <input
                v-model="directTrafficLocation"
                value="WEB"
                name="ac-conversion-location"
                id="WEB"
                class="radio-button__input"
                type="radio"
              />
              <label for="WEB" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">Web</span>
                <span class="d-block small-text">
                  Drive traffic to your website.
                </span>
              </label>
            </div>
            <div class="radio-button">
              <input
                v-model="directTrafficLocation"
                value="CALL"
                name="ac-conversion-location"
                id="CALL"
                class="radio-button__input"
                type="radio"
              />
              <label for="CALL" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">Call</span>
                <span class="d-block small-text"
                  >Get Snapchatters to call your business.
                </span>
              </label>
            </div>
            <div class="radio-button">
              <input
                v-model="directTrafficLocation"
                value="TEXT"
                name="ac-conversion-location"
                id="TEXT"
                class="radio-button__input"
                type="radio"
              />
              <label for="TEXT" class="radio-button__label">
                <span class="radio-button__custom"></span>
                <span class="d-block">Text</span>
                <span class="d-block small-text"
                  >Get Snapchatters to text your business.
                </span>
              </label>
            </div>
          </div>
        </div>
        <label for="" class="d-block status-title">Status</label>
        <switch-toogle
          :checked="ad_set_status"
          @tooglePaused="toogle_ad_set_status"
          paused="false"
        ></switch-toogle>
      </div>
    </v-card>
  </div>
</template>

<script>
import SwitchToogle from "@/components/snapchat/Label&input/SwitchToogle.vue";

export default {
  name: "AdSetDetailsForm",
  components: { SwitchToogle },
  data() {
    return {
      promotePlaces: false,
      placeName: "",
      zipCode: "",
      alertNameZipCode: true,
      result: null,
      ad_set_details: localStorage.getItem("ad_set_details"),
      directTrafficLocation:
        localStorage.getItem("direct_traffic_location") || "LEAD-FORM",
      labeltext: "Ad Set Name",
      ad_set_status: false,
      selectedOption: localStorage.getItem("selectedOption"),
      isValidate: {
        ad_set_details: false,
      },
    };
  },
  methods: {
    statusOfPromotePlaces() {
      if (localStorage.getItem("promote-places") === "true") {
        this.promotePlaces = true;
      }
    },
    checkInputs() {
      if (this.placeName && this.zipCode) {
        this.alertNameZipCode = false;
      }
    },
    searchPlace() {
      if (!this.placeName || !this.zipCode) {
        this.alertNameZipCode = true;
        this.result = null;
      } else {
        this.alertNameZipCode = false;
        this.result = `Results for ${this.placeName}, ${this.zipCode}`;
      }
    },
    isButtonEnabled() {
      return this.placeName && this.zipCode;
    },
    toogle_ad_set_status() {
      this.ad_set_status = !this.ad_set_status;
      localStorage.setItem("ad_set_status", this.ad_set_status);
    },
    checkValidation() {
      if (this.ad_set_details === "") {
        this.isValidate.ad_set_details = false;
      } else {
        this.isValidate.ad_set_details = true;
      }
    },
    sendValidation() {
      this.checkValidation();
      return Object.values(this.isValidate).every((value) => value);
    },
  },
  watch: {
    ad_set_details() {
      localStorage.setItem("ad_set_details", this.ad_set_details);
      this.checkValidation();
    },
    placeName() {
      this.checkInputs();
    },
    zipCode() {
      this.checkInputs();
    },
    directTrafficLocation(v) {
      if (this.directTrafficLocation == "APP") {
        this.appSelected = true;
      } else {
        this.appSelected = false;
      }
      localStorage.setItem("app_selected", this.appSelected);
      localStorage.setItem("direct_traffic_location", v);
      this.$emit("refresh-placements");
    },
  },
  mounted() {
    this.statusOfPromotePlaces();
    if (localStorage.getItem("ad_set_status")) {
      this.ad_set_status = localStorage.getItem("ad_set_status") === "true";
    } else {
      this.ad_set_status = true;
      localStorage.setItem("ad_set_status", this.ad_set_status);
    }
  },
};
</script>
