<template>
  <v-layout class="position-relative">
    <router-view />
  </v-layout>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
  },
};
</script>

<style scoped>
.mt-20 {
  margin-top: 90px;
}
</style>
