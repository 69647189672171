<template>
  <div class="performance-goal-contant">
    <div
      class="performance-goal-input d-flex"
      :class="{ 'border-bottom': selectPerformance }"
      @click="toggletreeSelect"
    >
      <p>{{ selectedGoal }}</p>
      <span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="#444546"
          xmlns="http://www.w3.org/2000/svg"
          class="sds-icon sds-icon-chevron-down sds-dropdown-arrow"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.04903 8.94148L11.8176 5.17288L12.7604 6.11569L8.04903 10.8271L3.33763 6.11569L4.28044 5.17288L8.04903 8.94148Z"
          ></path>
        </svg>
      </span>
    </div>
    <div class="tree-select" v-if="selectPerformance">
      <!-- Awareness Goals Section -->
      <p class="head-title-select" @click="togglecontant">
        <span v-if="awarenessGoalsContant">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#444546"
            xmlns="http://www.w3.org/2000/svg"
            class="sds-icon sds-icon-chevron-up styles-ion56l medium secondary"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.04903 7.05849L11.8176 10.8271L12.7604 9.88428L8.04903 5.17287L3.33763 9.88428L4.28044 10.8271L8.04903 7.05849Z"
            ></path>
          </svg>
        </span>
        <span v-else>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#444546"
            xmlns="http://www.w3.org/2000/svg"
            class="sds-icon sds-icon-chevron-down styles-ion56l medium secondary"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.04903 8.94148L11.8176 5.17288L12.7604 6.11569L8.04903 10.8271L3.33763 6.11569L4.28044 5.17288L8.04903 8.94148Z"
            ></path>
          </svg>
        </span>
        Awareness Goals
      </p>
      <ul v-if="awarenessGoalsContant">
        <li v-for="goal in awarenessGoals" :key="goal.id">
          <div class="radio-button">
            <input
              @change="setSelectedGoal"
              name="tree-select"
              :id="'radio' + goal.id"
              v-model="selectedGoal"
              class="radio-button__input"
              type="radio"
              :value="goal.value"
            />
            <label :for="'radio' + goal.id" class="radio-button__label">
              <span class="radio-button__custom"></span>
              <span class="d-block select-title">{{ goal.title }}</span>
              <span class="d-block select-description">{{
                goal.description
              }}</span>
            </label>
          </div>
        </li>
      </ul>

      <!-- Video View Goals Section -->
      <p class="head-title-select" @click="toggleVideoGoals">
        <span v-if="videoViewGoal">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#444546"
            xmlns="http://www.w3.org/2000/svg"
            class="sds-icon sds-icon-chevron-up styles-ion56l medium secondary"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.04903 7.05849L11.8176 10.8271L12.7604 9.88428L8.04903 5.17287L3.33763 9.88428L4.28044 10.8271L8.04903 7.05849Z"
            ></path>
          </svg>
        </span>
        <span v-else>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#444546"
            xmlns="http://www.w3.org/2000/svg"
            class="sds-icon sds-icon-chevron-down styles-ion56l medium secondary"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.04903 8.94148L11.8176 5.17288L12.7604 6.11569L8.04903 10.8271L3.33763 6.11569L4.28044 5.17288L8.04903 8.94148Z"
            ></path>
          </svg>
        </span>
        Video View Goals
      </p>
      <ul v-if="videoViewGoal">
        <li v-for="videoGoal in videoViewGoals" :key="videoGoal.id">
          <div class="radio-button">
            <input
              @change="setSelectedGoal"
              name="tree-select"
              :id="'radio' + videoGoal.id"
              class="radio-button__input"
              type="radio"
              :value="videoGoal.title"
              v-model="selectedGoal"
            />
            <label :for="'radio' + videoGoal.id" class="radio-button__label">
              <span class="radio-button__custom"></span>
              <span class="d-block select-title">{{ videoGoal.title }}</span>
              <span class="d-block select-description">{{
                videoGoal.description
              }}</span>
            </label>
          </div>
        </li>
      </ul>

      <!-- Engagement Goals Section -->
      <p class="head-title-select" @click="toggleEngagementGoals">
        <span v-if="engagementGoals">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#444546"
            xmlns="http://www.w3.org/2000/svg"
            class="sds-icon sds-icon-chevron-up styles-ion56l medium secondary"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.04903 7.05849L11.8176 10.8271L12.7604 9.88428L8.04903 5.17287L3.33763 9.88428L4.28044 10.8271L8.04903 7.05849Z"
            ></path>
          </svg>
        </span>
        <span v-else>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#444546"
            xmlns="http://www.w3.org/2000/svg"
            class="sds-icon sds-icon-chevron-down styles-ion56l medium secondary"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.04903 8.94148L11.8176 5.17288L12.7604 6.11569L8.04903 10.8271L3.33763 6.11569L4.28044 5.17288L8.04903 8.94148Z"
            ></path>
          </svg>
        </span>
        Engagement Goals
      </p>
      <ul v-if="engagementGoals">
        <li v-for="goal in engagementGoalsSelect" :key="goal.id">
          <div class="radio-button">
            <input
              @change="setSelectedGoal"
              name="tree-select"
              :id="'radio' + goal.id"
              class="radio-button__input"
              type="radio"
              :value="goal.value"
              v-model="selectedGoal"
            />
            <label :for="'radio' + goal.id" class="radio-button__label">
              <span class="radio-button__custom"></span>
              <span class="d-block select-title">{{ goal.title }}</span>
              <span class="d-block select-description">{{
                goal.description
              }}</span>
            </label>
          </div>
        </li>
      </ul>

      <!-- App Goals Section -->
      <p class="head-title-select" @click="toggleAppGoals">
        <span v-if="appGoalsVisible">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#444546"
            xmlns="http://www.w3.org/2000/svg"
            class="sds-icon sds-icon-chevron-up styles-ion56l medium secondary"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.04903 7.05849L11.8176 10.8271L12.7604 9.88428L8.04903 5.17287L3.33763 9.88428L4.28044 10.8271L8.04903 7.05849Z"
            ></path>
          </svg>
        </span>
        <span v-else>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#444546"
            xmlns="http://www.w3.org/2000/svg"
            class="sds-icon sds-icon-chevron-down styles-ion56l medium secondary"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.04903 8.94148L11.8176 5.17288L12.7604 6.11569L8.04903 10.8271L3.33763 6.11569L4.28044 5.17288L8.04903 8.94148Z"
            ></path>
          </svg>
        </span>
        App Goals
      </p>
      <ul v-if="appGoalsVisible">
        <li v-for="goal in appGoals" :key="goal.id">
          <div class="radio-button">
            <input
              @change="setSelectedGoal"
              name="tree-select"
              :id="'radio' + goal.id"
              class="radio-button__input"
              type="radio"
              :value="goal.value"
              v-model="selectedGoal"
            />
            <label :for="'radio' + goal.id" class="radio-button__label">
              <span class="radio-button__custom"></span>
              <span class="d-block select-title">{{ goal.title }}</span>
              <span class="d-block select-description">{{
                goal.description
              }}</span>
            </label>
          </div>
        </li>
      </ul>

      <!-- Conversion Goals Section -->
      <p class="head-title-select" @click="toggleConversionGoals">
        <span v-if="conversionGoalsVisible">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#444546"
            xmlns="http://www.w3.org/2000/svg"
            class="sds-icon sds-icon-chevron-up styles-ion56l medium secondary"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.04903 7.05849L11.8176 10.8271L12.7604 9.88428L8.04903 5.17287L3.33763 9.88428L4.28044 10.8271L8.04903 7.05849Z"
            ></path>
          </svg>
        </span>
        <span v-else>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#444546"
            xmlns="http://www.w3.org/2000/svg"
            class="sds-icon sds-icon-chevron-down styles-ion56l medium secondary"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.04903 8.94148L11.8176 5.17288L12.7604 6.11569L8.04903 10.8271L3.33763 6.11569L4.28044 5.17288L8.04903 8.94148Z"
            ></path>
          </svg>
        </span>
        Conversion Goals
      </p>
      <ul v-if="conversionGoalsVisible">
        <li v-for="goal in conversionGoals" :key="goal.id">
          <div class="radio-button">
            <input
              @change="setSelectedGoal"
              name="tree-select"
              :id="'radio' + goal.id"
              class="radio-button__input"
              type="radio"
              :value="goal.value"
              v-model="selectedGoal"
            />
            <label :for="'radio' + goal.id" class="radio-button__label">
              <span class="radio-button__custom"></span>
              <span class="d-block select-title">{{ goal.title }}</span>
              <span class="d-block select-description">{{
                goal.description
              }}</span>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "PerformanceGoals",

  props: {
    selected: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      selectedGoal: this.selected,
      selectPerformance: false,
      awarenessGoalsContant: true,
      videoViewGoal: true,
      engagementGoals: true,
      appGoalsVisible: true,
      conversionGoalsVisible: true,

      awarenessGoals: [
        {
          id: 1,
          title: "Maximize number of impressions",
          value: "IMPRESSIONS",
          description:
            "Ads will be shown to Snapchatters as many times as possible.",
        },
      ],
      videoViewGoals: [
        {
          id: 2,
          title: "Maximize 15-second video views",
          value: "VIDEO_VIEWS_15_SEC",
          description:
            "Video Ads will be shown to people likely to watch 15 continuous seconds or more.",
        },
        {
          id: 3,
          title: "Maximize 2-second video views",
          value: "VIDEO_VIEWS_2_SEC",
          description:
            "Video Ads will be shown to people who are likely to watch 2 continuous seconds or more.",
        },
      ],
      engagementGoalsSelect: [
        {
          id: 4,
          title: "Maximize number of Story opens",
          value: "STORY_OPENS",
          description:
            "Story Ads will be shown to Snapchatters who are most likely to open them.",
        },
        {
          id: 5,
          title: "Maximize number of clicks",
          value: "CLICKS",
          description:
            "Ads will be shown to Snapchatters who are most likely to click on them.",
        },
        {
          id: 6,
          title: "Maximize number of shares",
          value: "SHARES",
          description:
            "Ads will be shown to Snapchatters who are most likely to share them.",
        },
      ],
      appGoals: [
        {
          id: 7,
          title: "Maximize app purchases",
          value: "APP_PURCHASES",
          description:
            "Ads will be shown to users most likely to complete a purchase in the app.",
        },
        {
          id: 8,
          title: "Maximize app signups",
          value: "APP_SIGNUPS",
          description:
            "Ads will be shown to users most likely to sign up for the app.",
        },
        {
          id: 9,
          title: "Maximize app reengagement",
          value: "APP_REENGAGEMENT",
          description:
            "Ads will target users most likely to reengage with your app.",
        },
      ],
      conversionGoals: [
        {
          id: 10,
          title: "Maximize website purchases",
          value: "WEBSITE_PURCHASES",
          description:
            "Ads will target users most likely to make purchases on your website.",
        },
        {
          id: 11,
          title: "Maximize signups",
          value: "WEBSITE_SIGNUPS",
          description:
            "Ads will target users most likely to complete a signup on your website.",
        },
        {
          id: 12,
          title: "Maximize add-to-cart events",
          value: "ADD_TO_CART",
          description:
            "Ads will target users most likely to add items to their cart on your website.",
        },
      ],
    };
  },

  methods: {
    togglecontant() {
      this.awarenessGoalsContant = !this.awarenessGoalsContant;
    },

    toggleVideoGoals() {
      this.videoViewGoal = !this.videoViewGoal;
    },

    toggleEngagementGoals() {
      this.engagementGoals = !this.engagementGoals;
    },

    toggleAppGoals() {
      this.appGoalsVisible = !this.appGoalsVisible;
    },

    toggleConversionGoals() {
      this.conversionGoalsVisible = !this.conversionGoalsVisible;
    },

    toggletreeSelect() {
      this.selectPerformance = !this.selectPerformance;
    },

    setSelectedGoal() {
      this.$emit("selectedGoal", this.selectedGoal);
    },
  },
};
</script>

<style scoped>
.tree-select {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 10px 15px;
  bottom: 20px;
  width: 100%;
  position: relative;
  height: max-content;
  top: 15px;
  z-index: 99;
}

.tree-select ul {
  list-style: none;
}

.tree-select ul li {
  padding-left: 25px;
  margin-bottom: 10px;
  padding: 10px 25px 10px 25px;
  border-radius: 8px;
}

.tree-select ul li:hover {
  background-color: rgb(249, 249, 250);
}

.tree-select .head-title-select {
  cursor: pointer;
  color: rgb(84, 85, 87);
  font-size: 14px;
  margin-bottom: 10px;
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 7px;
}

.tree-select .head-title-select::after {
  content: "";
  width: 0;
  height: 2px;
  background-color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.2s ease-in;
}

.tree-select .head-title-select:hover::after {
  width: 100%;
}

.tree-select .radio-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.tree-select .radio-button__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.tree-select .radio-button__label {
  display: inline-block;
  padding-left: 30px;
  margin-bottom: 10px;
  position: relative;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.tree-select .radio-button__custom {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #555;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.tree-select
  .radio-button__input:checked
  + .radio-button__label
  .radio-button__custom {
  transform: translateY(-50%) scale(0.9);
  border: 5px solid #000;
  color: #000;
}

.tree-select .radio-button__input:checked + .radio-button__label {
  color: #000;
}

.tree-select .radio-button__label:hover .radio-button__custom {
  transform: translateY(-50%);
  border-color: #000;
}

.border-bottom {
  border-bottom: 2px solid #f7d901 !important;
}

.select-title {
  color: rgb(0, 0, 0);
  font-size: 14px;
}

.select-description {
  color: rgb(84, 85, 87);
  font-size: 12px;
}
</style>
