<template>
  <v-container
    fluid=""
    style="margin-top: 0%; padding: 0% 0% 0 0; min-height: calc(100vh - 58px)"
  >
    <v-row
      style="height: 100%; margin: 0; padding: 0px 0px 0 0px; position: relative"
    >
      <v-col cols="12" class="pa-0">
        <GlobalNav />
      </v-col>
      <v-col
        cols="12"
        style="height: 100%"
        class="pa-0 d-flex align-start ga-6"
      >
        <SideBar />
        <div class="insights-contant mt-7 w-100">
          <BreadCrumb :linksData="linksData" class="mb-5" />
          <v-row>
            <v-col cols="9" sm="6" md="9">
              <v-card
                style="
                  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.2);
                  height: 100%;
                "
                class="insights-card px-5 pt-5 pb-14 rounded-lg"
              >
                <div class="charts-title position-absolute">
                  <p>Paid Impressions By</p>
                  <h4>Age & Gender</h4>
                </div>
                <BarChart />
              </v-card>
            </v-col>
            <v-col cols="3" sm="6" md="3">
              <v-card
                class="total-impersions mb-3 d-flex align-center justify-space-between"
              >
                <div class="imperions-data">
                  <div class="mb-4 imperions-data-sub">
                    <p>{{formattedImpressions}}</p>
                    <span>Total Paid Impressions</span>
                  </div>
                  <div class="piad-ecpm">
                    <p>
                      <span>$9.70</span>
                      <span>Paid ECPM</span>
                    </p>
                  </div>
                </div>
                <div class="imperions-icon">
                  <div class="shadow-lg">
                    <img
                      src="@/assets/soft-ware-img/impressions-icon.svg"
                      alt=""
                    />
                  </div>
                </div>
              </v-card>
              <v-card class="male-female-statistics mb-3">
                <div
                  class="male-statistics mb-2 d-flex align-center justify-space-between px-2 py-3"
                >
                  <div style="width: 80%" class="overlay"></div>
                  <div class="male-number">
                    <p>64.2%</p>
                    <p>Male</p>
                  </div>
                  <div class="male-impressions">
                    <p>62,456 Paid impressions</p>
                  </div>
                </div>
                <div
                  class="male-statistics d-flex align-center justify-space-between px-2 py-3"
                >
                  <div style="width: 80%" class="overlay"></div>
                  <div class="male-number">
                    <p>64.2%</p>
                    <p>Female</p>
                  </div>
                  <div class="male-impressions">
                    <p>62,456 Paid impressions</p>
                  </div>
                </div>
              </v-card>
              <v-card class="male-female-statistics">
                <div
                  class="male-statistics mb-2 d-flex align-center justify-space-between px-2 py-3"
                >
                  <div style="width: 80%" class="overlay"></div>
                  <div class="male-number">
                    <p>64.2%</p>
                    <p>Ios</p>
                  </div>
                  <div class="male-impressions">
                    <p>62,456 Paid impressions</p>
                  </div>
                </div>
                <div
                  class="male-statistics d-flex align-center justify-space-between px-2 py-3"
                >
                  <div style="width: 80%" class="overlay"></div>
                  <div class="male-number">
                    <p>64.2%</p>
                    <p>Android</p>
                  </div>
                  <div class="male-impressions">
                    <p>62,456 Paid impressions</p>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="9" sm="6" md="9">
              <div class="card top-inserts">
                <div class="card-header">
                  <h2>Top Interests</h2>
                  <v-select
                    :items="items"
                    density="compact"
                    label="Sort by Paid Impressions"
                    variant="solo"
                  ></v-select>
                </div>
                <div class="card-body py-5">
                  <div
                    class="shoppers d-flex align-center justify-space-between mb-8"
                  >
                    <p>Shoppers</p>
                    <div class="progress-number d-flex align-center ga-2">
                      <span style="font-size: 14px; font-weight: 400"
                        >91,090</span
                      >
                      <div class="progress-bar">
                        <span style="width: 63%"></span>
                      </div>
                      <span style="font-size: 14px; font-weight: 600">63%</span>
                    </div>
                  </div>
                  <div
                    class="shoppers d-flex align-center justify-space-between mb-8"
                  >
                    <p>Travel Enthusiats</p>
                    <div class="progress-number d-flex align-center ga-2">
                      <span style="font-size: 14px; font-weight: 400"
                        >90,254</span
                      >
                      <div class="progress-bar">
                        <span style="width: 88%"></span>
                      </div>
                      <span style="font-size: 14px; font-weight: 600">88%</span>
                    </div>
                  </div>
                  <div
                    class="shoppers d-flex align-center justify-space-between mb-3"
                  >
                    <p>News Watchers</p>
                    <div class="progress-number d-flex align-center ga-2">
                      <span style="font-size: 14px; font-weight: 400"
                        >85,254</span
                      >
                      <div class="progress-bar">
                        <span style="width: 38%"></span>
                      </div>
                      <span style="font-size: 14px; font-weight: 600">38%</span>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="3" sm="6" md="3">
              <div class="card top-inserts top-location">
                <div class="card-header">
                  <h2>Top Locations</h2>
                </div>
                <div
                  class="card-body d-flex align-center justify-space-between pt-5"
                >
                  <div>
                    <p>Saudi Arabia</p>
                    <p>97,254</p>
                  </div>
                  <div class="chart-container">
                    <Doughnut
                      style="width: 150px; height: 150px"
                      :data="chartData"
                      :options="chartOptions"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GlobalNav from "@/components/layout/GlobalNav.vue";
import SideBar from "@/components/layout/SideBar.vue";
import BreadCrumb from "@/components/layout/BreadCrumb.vue";
import BarChart from "@/components/BarChart.vue";
import { Doughnut } from "vue-chartjs";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import apiClient from "@/core/buildRequest";

ChartJS.register(ArcElement, Tooltip, Legend);
export default {
  components: {
    GlobalNav,
    SideBar,
    BreadCrumb,
    BarChart,
    Doughnut,
  },
  data() {
    return {
      linksData: [
        { name: "Ads", link: "/home-page" },
        { name: "Ads Performance", link: "" },
      ],
      stats: {
        impressions: 0,
        nativeLeads: 0,
        profileClicks: 0,
        swipes: 0,
        paidImpressions: 0,
        androidInstalls: 0
      },
      
    };
  },
  computed: {
    userId() {
      return this.$route.params.id;
    },
    chartData() {
      return {
        labels: ["Impressions"],
        datasets: [
          {
            backgroundColor: ["rgba(35, 114, 118, 1)"],
            data: [60],
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
          },
        },
      };
    },
    formattedImpressions() {
      return this.stats.impressions.toLocaleString('en-US');
    },
  },
  mounted() {
    if (!this.$route.params.id) {
      console.error("Campaign ID is missing from route params");
      return;
    }

    const bodyData = {
      campaignId: [this.$route.params.id],
      matrices: [
        "impressions",
        "swipes",
        "native_leads",
        "paid_impressions",
        "profile_clicks",
        "android_installs",
      ],
    };
    apiClient
      .post("/snapchat/camapign/states", bodyData)
      .then((response) => {
        const statsData = response.data?.data;
        console.log(response);
        if (!statsData || !Array.isArray(statsData)) {
          console.error("Invalid stats data format:", statsData);
          return;
        }

        // Find stats for the campaign
        const campaignStats = statsData[0]?.total_stats?.[0]?.total_stat?.stats;

        if (campaignStats) {
          this.stats = {
            impressions: campaignStats.impressions || 0,
            nativeLeads: campaignStats.native_leads || 0,
            profileClicks: campaignStats.profile_clicks || 0,
            swipes: campaignStats.swipes || 0,
            paidImpressions: campaignStats.paid_impressions || 0,
            androidInstalls: campaignStats.android_installs || 0,
          };
        }
      })
      .catch((error) => {
        console.error("Failed to fetch campaign stats:", error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
