<template>
  <div>
    <span class="upload-span" @click="showFileChooser">Upload</span>
    <input
      ref="input"
      type="file"
      name="image"
      accept="image/*,video/*"
      @change="setImage"
      style="display: none"
    />
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">Crop Image</v-card-title>
        <v-card-text>
          <vue-cropper
            ref="cropper"
            :aspect-ratio="ImageMinWidth / ImageMinHeight"
            :src="imgSrc"
            preview=".preview"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="crop-btn" @click="cropImage">
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="#444546"
                xmlns="http://www.w3.org/2000/svg"
                class="sds-icon sds-icon-scissor"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.23239 11.9217C5.01016 12.1535 4.75076 12.1879 4.61528 12.1879C4.48062 12.1879 4.22122 12.1535 3.999 11.9217C3.65946 11.5689 3.65946 10.9938 3.999 10.641C4.22122 10.4092 4.48062 10.3748 4.61528 10.3748C4.75076 10.3748 5.01016 10.4092 5.23239 10.641C5.57275 10.9938 5.57275 11.5689 5.23239 11.9217ZM5.31665 5.35904C5.09443 5.59082 4.83503 5.62515 4.69954 5.62515C4.56489 5.62515 4.30548 5.59082 4.08326 5.35904C3.7429 5.00622 3.7429 4.43108 4.08326 4.07826C4.30466 3.84734 4.56489 3.81301 4.69954 3.81301C4.83503 3.81301 5.09443 3.84734 5.31665 4.07826C5.65619 4.43108 5.65619 5.00622 5.31665 5.35904ZM13.8711 12.957L9.06309 7.96867L13.686 3.2267C13.9504 2.95715 13.7901 2.48759 13.4217 2.45325C12.7476 2.39059 11.9132 2.35968 11.7174 2.56399L7.80657 6.66557L7.1655 6.00114C7.12832 5.96166 7.08041 5.94277 7.03828 5.91187C7.51743 4.8972 7.36129 3.64132 6.54922 2.79662C6.03868 2.26611 5.36952 2 4.69954 2C4.03039 2 3.36041 2.26611 2.84987 2.79662C1.82796 3.85764 1.82796 5.5788 2.84987 6.64153C3.36041 7.17204 4.03039 7.4373 4.69954 7.4373C5.09443 7.4373 5.48518 7.33343 5.84784 7.14801C5.87758 7.19265 5.89493 7.24243 5.93293 7.28192L6.57896 7.95322L5.8495 8.71894C5.8115 8.75757 5.79332 8.80735 5.76441 8.85199C5.40174 8.66657 5.01099 8.5627 4.61528 8.5627C3.94612 8.5627 3.27697 8.82796 2.76643 9.35932C1.74452 10.4212 1.74452 12.1424 2.76643 13.2034C3.27697 13.7339 3.94612 14 4.61528 14C5.28526 14 5.95524 13.7339 6.46578 13.2034C7.27785 12.3595 7.43316 11.1028 6.95401 10.0881C6.99615 10.0572 7.04406 10.0392 7.08206 9.99971L7.81978 9.24258L12.0991 13.6892C12.3973 14.0858 13.159 13.9073 13.71 13.7056C14.0132 13.5948 14.0983 13.1931 13.8711 12.957Z"
                ></path>
              </svg>
            </span>
            Crop Image
          </span>
          <span class="cancel-btn" @click="dialog = false">Cancel</span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  components: {
    VueCropper,
  },
  props: [
    "file",
    "ImageMinWidthProp",
    "ImageMinHeightProp",
    "VideoMaxWidthProp",
    "VideoMaxHeightProp",
  ],
  data() {
    return {
      imgSrc: "",
      videoSrc: "",
      cropImg: "",
      dialog: false,
      ImageMinWidth: this.ImageMinWidthProp || 400,
      ImageMinHeight: this.ImageMinHeightProp || 700,
      VideoMaxWidth: this.VideoMaxWidthProp || 900,
      VideoMaxHeight: this.VideoMaxHeightProp || 1600,
      VideoaspectRation: this.VideoaspectRationProp || 9 / 16,
      fileObject: null, // To store the file object
      fileUrl: null, // To store the file URL
    };
  },
  methods: {
    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.dialog = false;
      this.videoSrc = "";
      this.$emit("update-image", this.cropImg);
      this.$emit("update-video", this.videoSrc);
      this.$emit("update-file", this.fileObject);
      // this.$emit("update-file-url", this.fileUrl);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    setImage(e) {
      const file = e.target.files[0];
      if (
        file.type.indexOf("image/") === -1 &&
        file.type.indexOf("video/") === -1
      ) {
        alert("Please select an image or video file");
        return;
      } else {
        if (file.type.indexOf("image/") !== -1) {
          const reader = new FileReader();
          reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;

            img.onload = () => {
              if (
                img.width < this.ImageMinWidth ||
                img.height < this.ImageMinHeight
              ) {
                alert(
                  `Image is too small! Please upload an image with minimum dimensions of ${this.ImageMinWidth}px by ${this.ImageMinHeight}px.`
                );
                return;
              }
            };

            this.imgSrc = event.target.result;
            this.dialog = true;
          };
          reader.readAsDataURL(file);
        } else if (file.type.indexOf("video/") !== -1) {
          this.videoSrc = URL.createObjectURL(file);
          this.dialog = true;
        }
        this.fileObject = file;
        this.fileUrl = URL.createObjectURL(file);
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
  },
};
</script>
