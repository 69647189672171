import SignupView from "@/views/SignupView";
import ErrorPage from "@/views/ErrorPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import HomePage from "@/views/HomePage.vue";
const generalRoutes = [
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignupView,
    meta: {
      title: "Sign up",
      description: "",
      auth: "unauth",
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: ErrorPage,
    meta: {
      title: "Not Found",
      description: "",
    },
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
    meta: {
      title: "login",
      description: "",
      auth: "unauth",
    },
  },
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: {
      title: "HomePage",
      description: "",
      auth: "auth",
    },
  },
];

export default generalRoutes;
