<template>
  <div class="side-nav-parent">
    <div class="sidenav-header">
      <p class="d-flex align-center active">
        <span class="d-block">
          <img
            v-if="activeClass"
            src="@/assets/snapchatImage/download (5).svg"
            alt=""
          />
          <img v-else src="@/assets/snapchatImage/download (4).svg" alt="" />
        </span>
        <span class="d-block">{{ firstTile }}</span>
      </p>
      <hr />
    </div>
  </div>
</template>

<script>
import "@/assets/snapchatStyle/snapchatStyle.css";
export default {
  name: "SideName",
  props: {
    firstTile: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeClass: true,
    };
  },
};
</script>
