<template>
  <Bar style="height:356px"  :data="chartData" :options="chartOptions" />
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

// Register the required components for the bar chart
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "LineChart",
  components: { Bar },
  data() {
    return {
      // Define the chart data
      chartData: {
        labels: [
          "13-17",
          "18-20",
          "21-24",
          "24-34",
          "35-55",
        ],
        datasets: [
        {
            label: "Female",
            backgroundColor: "rgba(237, 237, 237, 1)",
            borderColor: "#4fc3f7",
            data: [20, 40, 15, 45, 20],
            borderRadius:10,
            fill: true,
          },
          {
            label: "Male",
            backgroundColor: "rgba(53, 140, 145,0.7)",
            borderColor: "#f87979",
            borderRadius:10,
            data: [30,50,20,60,40],
            fill: true,
            
          },
          
        ],
      },

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Chart with Two Lines",
          },
        },
      },
    };
  },
};
</script>
