<template>
  <v-dialog
    v-model="create_profile_engagement_audiance_dialog"
    max-width="55%"
    persistent
  >
    <v-card class="pa-5 radius-10">
      <div>
        <span>Create New Customer List Audience</span>
      </div>
      <div class="body mt-3">
        <div class="filed mt-3">
          <span class="title">Audiance Name</span>
          <input
            type="text"
            v-model="audiance_name"
            placeholder="Enter Your Audiance Name"
          />
          <div class="warning">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#444546"
              xmlns="http://www.w3.org/2000/svg"
              class="warning-svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13 8C13 10.7614 10.7614 13 8 13V8V3C10.7614 3 13 5.23858 13 8ZM8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
              ></path>
            </svg>
            <p class="small-text muted-text">
              The audience must be given a name
            </p>
          </div>
        </div>
        <div class="filed">
          <span class="title">Public Profile</span>
          <div class="profile-pic d-flex align-center ga-3">
            <div class="pic">
              <img
                src="https://cf-st.sc-cdn.net/d/YmmIFdcbee3keYymYPlJq?bo=Eg0aABoAMgEESAJQCGAB&uc=8"
                alt=""
              />
            </div>
            <div class="name d-flex flex-column ga-1">
              <div class="">Mohamed</div>
              <div class="small-text muted-text">Helmy</div>
            </div>
          </div>
        </div>
        <div class="filed mt-3">
          <span class="title">Audience Definition</span>
          <div class="tree-select">
            <div class="radio-button">
              <input
                v-model="audiance_definition"
                value="audiance_definition_profile"
                name="audiance_definition"
                id="audiance_definition_profile"
                class="radio-button__input"
                type="radio"
              />
              <label
                for="audiance_definition_profile"
                class="radio-button__label"
              >
                <span class="radio-button__custom"></span>
                <span class="d-block">Subscriber to Profile</span>
                <span class="small-text muted-text"
                  >People who have subscribed to your profile despite of time of
                  action</span
                >
              </label>
            </div>
            <div class="radio-button">
              <input
                v-model="audiance_definition"
                value="audiance_definition_actions"
                name="audiance_definition"
                id="audiance_definition_actions"
                class="radio-button__input"
                type="radio"
              />
              <label
                for="audiance_definition_actions"
                class="radio-button__label"
              >
                <span class="radio-button__custom"></span>
                <span class="d-block"
                  >Snapchatters who have done one of the following actions</span
                >
              </label>
            </div>
            <div
              v-if="audiance_definition === 'audiance_definition_actions'"
              class="audiance_definition_actions ml-7"
            >
              <div
                v-if="audiance_definition_selected_actions.length > 0"
                class="border p-3 mb-3"
                style="border-radius: 8px"
              >
                <div class="single-selected">
                  <div class="mt-2">
                    <div
                      class=""
                      v-for="(
                        action, index
                      ) in audiance_definition_selected_actions"
                      :key="index"
                    >
                      <div class="d-flex ga-2">
                        <div class="body selected-language-button">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="#444546"
                            xmlns="http://www.w3.org/2000/svg"
                            class="correct-svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z"
                            ></path>
                          </svg>
                          <span>{{ action }}</span>
                          <button
                            class="remove-btn"
                            @click="removeFromSelectedActionsList(index)"
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="#444546"
                              xmlns="http://www.w3.org/2000/svg"
                              class="sds-icon sds-icon-cross"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <v-select
                variant="solo"
                class="v-select-style"
                v-model="audiance_definition_selected_actions"
                :items="audiance_definition_actions_list"
                multiple
              ></v-select>
              <div class="filed mt-3">
                <span class="title">Duration (up to 395 days)</span>

                <div class="d-flex align-center ga-2" style="width: 200px">
                  <span class="small-text muted-text">in last</span>
                  <input
                    v-model="audiance_duration"
                    style="width: 70px"
                    type="number"
                  />
                  <span class="small-text muted-text">days</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 d-flex justify-end align-center ga-3">
        <a
          @click="create_profile_engagement_audiance_dialog = false"
          class="cancel-btn"
          >Cancel</a
        >
        <v-btn class="upload-button" :readonly="!upload_ready"> Create </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      create_profile_engagement_audiance_dialog: false,
      audiance_name: "",
      method_type: "upload_file",
      past_and_copy: "",
      data_type: "",
      description: "",
      upload_ready: false,
      pixelsItems: [],
      userActionsItems: [
        {
          id: 1,
          label: 'Click on "Add to Cart" button',
        },
        {
          id: 2,
          label: 'Click on "Add to Cart" button',
        },
        {
          id: 3,
          label: 'Click on "Add to Cart" button',
        },
      ],
      userActions: [],
      audiance_duration: 395,
      audiance_definition: "audiance_definition_profile",
      audiance_definition_selected_actions: [],
      audiance_definition_actions_list: [
        "Viewed profile",
        "Viewed Story",
        "Played With Lens",
        "Viewd Lens",
        "Viewed Spotlight",
        "Subscribed",
        "Unsubscribed",
      ],
    };
  },
  methods: {
    openDialog() {
      this.create_profile_engagement_audiance_dialog = true;
    },
    closeDialog() {
      this.create_profile_engagement_audiance_dialog = false;
    },
    handlePaste(event) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.clipboardData).getData(
        "text"
      );
      this.past_and_copy = pastedText;
    },
    clearOnBackspace(event) {
      if (event.key === "Backspace") {
        this.past_and_copy = "";
      }
    },
    removeFromSelectedActionsList(index) {
      this.audiance_definition_selected_actions.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.body-header {
  background: #fff;
  border: 1px solid #ffb18a;
  background-color: rgb(255, 255, 255);
  margin: 16px 0px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 8px 0px;
  display: flex;
  flex-direction: column;
  -webkit-box-flex: 0;
  margin-top: 20px;
}
.body-header .have-you {
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  padding: 16px 20px;
  font-weight: 700;
}
.body-header .desc {
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-height: 100000px;
  opacity: 1;
  will-change: max-height, opacity;
  overflow: auto;
  padding: 16px 20px;
  transition:
    opacity 100ms cubic-bezier(0.55, 0.055, 0.675, 0.19),
    max-height 250ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
  font-size: 12px;
}
.warning {
  display: flex;
  gap: 5px;
}
.warning-svg {
  display: flex;
  color: rgb(8, 148, 250);
  fill: rgb(8, 148, 250);
  flex-shrink: 0;
}
.blue-link {
  color: #3bf;
  text-decoration: none;
}
.blue-link:hover {
  color: #0894fa;
}

.gray-link {
  font-size: 12px;
  color: rgb(53, 56, 58);
}
.gray-link:hover {
  color: rgb(53, 56, 58);
}
input {
  background-color: #f9f9fa;
}

.filed .title {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.yellow-link {
  background-color: #f7d901;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.815);
}
.yellow-link:hover {
  background-color: unset;
  color: rgba(0, 0, 0, 0.815);
}

.cancel-btn {
  background: unset;
  border: unset;
  color: black;
  box-shadow: unset;
  background-color: unset;
  position: relative;
  padding-bottom: 5px;
  text-decoration: none;
  cursor: pointer;
}

.cancel-btn .v-btn__content {
  position: relative;
}

.cancel-btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: blue;
  transition: width 0.3s ease;
}

.cancel-btn:hover::after {
  width: 100%;
}

.cancel-btn:hover {
  color: blue;
}
.upload-button:hover {
  box-shadow: unset;
}
.upload-button {
  align-items: center;
  border: none;
  border-radius: 100px;
  box-shadow: none;
  box-sizing: border-box;
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  height: auto;
  justify-content: center;
  line-height: 1;
  min-height: 32px;
  outline: 1px;
  padding: 8px 20px;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  background: white;
  box-shadow: 0 0 0 1px #d1d3d5;
  color: #545557;
}
.upload-button.v-btn--readonly,
.v-input--disabled {
  background: #e1e3e5
    repeating-linear-gradient(
      45deg,
      #d1d3d5,
      #d1d3d5 1px,
      #e1e3e5 0,
      #e1e3e5 5px
    );
}
.upload-button:hover .v-btn__overlay {
  opacity: 0px;
}
.v-autocomplete__style:hover {
  border: 2px solid #f7d901 !important;
}
.vue-treeselect:not(.vue-treeselect--disabled)
  .vue-treeselect__multi-value-item:not(
    .vue-treeselect__multi-value-item-disabled
  ):hover
  .vue-treeselect__multi-value-item:not(.vue-treeselect__multi-value-item-new)
  .vue-treeselect__multi-value-item:not(
    .vue-treeselect__multi-value-item-new
  ):hover,
.vue-treeselect__multi-value-item {
  background: #f1f2f3 !important;
  color: #010f16 !important;
  border: 0.1px solid #c5c6c7 !important;
}
input[type="number"] {
  color: #4e4e4e;
}
.profile-pic {
  border-radius: 8px;
  border: 1px solid rgb(209, 211, 213);
  padding: 16px;
  background-color: #fff;
  background-clip: padding-box;
}
.pic {
  border-style: solid;
  border-radius: 50%;
  box-sizing: border-box;
  text-align: center;
  color: rgb(68, 69, 70);
  font-weight: bold;
  background-size: cover;
  border-color: rgb(195, 197, 199);
  width: 40px;
  height: 40px;
  line-height: 36px;
  border-width: 1px;
  overflow: hidden;
}
.pic img {
  width: 100%;
  height: 100%;
}
.selected-language-button {
  background-color: rgb(255, 255, 255);
  border-radius: 100px;
  border: 1px solid transparent;
  gap: 4px;
  color: rgb(84, 85, 87);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 8px;
}
.selected-language-button:hover {
  border-color: initial;
}
</style>
