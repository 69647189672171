<template>
  <div class="audiences ad-set-details-card mb-6 fs-14">
    <h6 class="page-header-title mb-3">Devices</h6>
    <v-card
      max-width="100%"
      class="pa-5 mb-5 rounded-lg"
      style="overflow: visible"
      elevation="1"
    >
      <div class="card-body">
        <span>Operating Systems</span>
        <div>
          <div class="d-flex align-center justify-start ga-2">
            <label class="snap-checkbox">
              <input
                :disabled="iOS_readonly"
                v-model="iOS_devices"
                type="checkbox"
              />
              <span class="snap-checkmark"></span>iOS
            </label>
          </div>
          <div class="d-flex align-center justify-start ga-2">
            <label class="snap-checkbox">
              <input
                :disabled="android_readonly"
                v-model="android_devices"
                type="checkbox"
              />
              <span class="snap-checkmark"></span>Android
            </label>
          </div>
          <div class="d-flex align-center justify-start ga-2">
            <label class="snap-checkbox">
              <input v-model="web_devices" type="checkbox" />
              <span class="snap-checkmark"></span>Web
            </label>
          </div>
          <div v-if="show_android_os_versions || show_ios_os_versions">
            <span>OS Version</span>
            <div
              v-if="show_android_os_versions"
              class="android-versions d-flex align-center ga-3"
            >
              <div class="min">
                <v-select
                  style="min-width: 200px"
                  class="v-select-style"
                  label="Min"
                  v-model="min_android_version"
                  :items="android_versions"
                ></v-select>
              </div>
              <div class="dash">-</div>
              <div class="max">
                <v-select
                  style="min-width: 200px"
                  class="v-select-style"
                  label="Max"
                  v-model="max_android_version"
                  :items="android_versions"
                ></v-select>
              </div>
            </div>
            <div
              v-if="show_ios_os_versions"
              class="ios-versions d-flex align-center ga-3"
            >
              <div class="min">
                <v-select
                  style="min-width: 200px"
                  class="v-select-style"
                  label="Min"
                  v-model="min_ios_version"
                  :items="iOS_versions"
                ></v-select>
              </div>
              <div class="dash">-</div>
              <div class="max">
                <v-select
                  style="min-width: 200px"
                  class="v-select-style"
                  label="Max"
                  v-model="max_ios_version"
                  :items="iOS_versions"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <span>Devices Maker</span>

          <div class="">
            <div class="mt-3">
              <div
                v-if="selected_devices_makers.length > 0"
                class="selected-custom-audiences mb-3"
              >
                <p
                  class="d-flex align-center justify-space-between"
                  v-for="(
                    selected_devices_maker, index
                  ) in selected_devices_makers"
                  :key="index"
                >
                  <span class="country d-flex align-center ga-2">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#444546"
                      xmlns="http://www.w3.org/2000/svg"
                      class="green-svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z"
                      ></path>
                    </svg>
                    {{ selected_devices_maker.label }}
                  </span>
                  <span
                    @click="removeSelectedDevicesMaker(index)"
                    class="cross"
                  >
                    <svg
                      style="cursor: pointer"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#33bbff"
                      xmlns="http://www.w3.org/2000/svg"
                      class="sds-icon sds-icon-cross"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
                      ></path>
                    </svg>
                  </span>
                </p>
              </div>
              <treeselect
                v-model="selected_devices_makers"
                :multiple="true"
                :options="devices_makers"
                :value-format="'object'"
              >
              </treeselect>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <span>Connection Type</span>
          <div class="connection-type d-flex ga-3">
            <button
              :class="selected_connection_type === 'all' ? 'selected' : ''"
              @click="selected_connection_type = 'all'"
            >
              All
            </button>
            <button
              v-for="connection_type in connection_types"
              :key="connection_type"
              :class="
                selected_connection_type === connection_type ? 'selected' : ''
              "
              @click="selected_connection_type = connection_type"
            >
              {{ connection_type }}
            </button>
          </div>
        </div>
        <div class="mt-3">
          <span>Carriers</span>
          <treeselect
            v-model="selected_carriers"
            :multiple="true"
            :options="foramttedCarriers"
            :value-format="'object'"
          >
          </treeselect>
          <div
            v-if="selected_carriers.length > 0"
            style="border: unset; padding: 5px"
            class="selected-custom-audiences mb-3"
          >
            <p
              class="d-flex align-center justify-space-between"
              v-for="(selected_carrier, index) in selected_carriers"
              :key="index"
            >
              <span class="country d-flex align-center ga-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#444546"
                  xmlns="http://www.w3.org/2000/svg"
                  class="green-svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 10.9394L12.803 4.13635L13.8637 5.19701L6 13.0607L2.13634 9.19701L3.197 8.13635L6 10.9394Z"
                  ></path>
                </svg>
                {{ selected_carrier.label }}
              </span>
              <span @click="removeSelectedCarriers(index)" class="cross">
                <svg
                  style="cursor: pointer"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#33bbff"
                  xmlns="http://www.w3.org/2000/svg"
                  class="sds-icon sds-icon-cross"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 9.8535L11.6239 13.4774L12.8614 12.24L9.23744 8.61606L12.8614 4.99214L11.6239 3.7547L8 7.37862L4.37609 3.75471L3.13865 4.99215L6.76256 8.61606L3.13864 12.24L4.37608 13.4774L8 9.8535Z"
                  ></path>
                </svg>
              </span>
            </p>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import Treeselect from "vue3-treeselect";
import apiClient from "@/core/buildRequest";

export default {
  components: { Treeselect },
  data() {
    return {
      iOS_readonly: false,
      iOS_devices: true,
      android_readonly: false,
      android_devices: true,
      web_devices: true,
      show_android_os_versions: false,
      show_ios_os_versions: false,
      min_android_version: localStorage.getItem("min_android_version") || "",
      android_versions: [
        "none",
        "4.3",
        "4.4",
        "4.4.1",
        "4.4.2",
        "5.0",
        "5.1",
        "6.0",
        "7.0",
        "7.1",
        "8.0",
        "8.1",
        "9.0",
        "10.0",
        "11.0",
        "12.0",
        "12.1",
        "13.0",
        "14.0",
      ],
      iOS_versions: [
        "none",
        "7.0",
        "7.1",
        "8.0",
        "8.1",
        "8.2",
        "8.3",
        "8.4",
        "8.4.1",
        "9.0",
        "9.1",
        "9.2",
        "9.3",
        "10.0",
        "10.1",
        "10.2",
        "10.3",
        "11.0",
        "11.1",
        "11.2",
        "11.3",
        "11.4",
        "12.0",
        "12.1",
        "12.2",
        "12.3",
        "12.4",
        "13.0",
        "13.1",
        "13.2",
        "13.3",
        "13.4",
        "13.5",
        "13.6",
        "13.7",
        "14.0",
        "14.1",
        "14.2",
        "14.3",
        "14.4",
        "14.5",
        "14.6",
        "14.7",
        "14.8",
        "15.0",
        "15.1",
        "15.2",
        "15.3",
        "15.4",
        "15.5",
        "15.6",
        "15.7",
        "16.0",
        "16.1",
        "16.2",
        "16.3",
        "16.4",
        "16.5",
        "16.6",
        "17.0",
        "17.1",
        "17.2",
        "17.3",
        "17.4",
      ],
      max_android_version: localStorage.getItem("max_android_version") || "",
      max_ios_version: localStorage.getItem("max_ios_version") || "",
      min_ios_version: localStorage.getItem("min_ios_version") || "",
      selected_devices_makers: localStorage.getItem("selected_devices_makers")
        ? JSON.parse(localStorage.getItem("selected_devices_makers"))
        : [],
      selected_connection_type:
        localStorage.getItem("selected_connection_type") || "all",
      connection_types: [],
      selected_carriers: localStorage.getItem("selected_carriers")
        ? JSON.parse(localStorage.getItem("selected_carriers"))
        : [],
      foramttedCarriers: [],
      devices_makers: [],
      available_devices: [],
      available_apple_devices: [],
      available_android_devices: [],

      carriers: [],
    };
  },
  methods: {
    showOsVersions() {
      if (this.iOS_devices && !this.android_devices && !this.web_devices) {
        this.show_ios_os_versions = true;
        this.show_android_os_versions = false;
      } else if (
        this.android_devices &&
        !this.iOS_devices &&
        !this.web_devices
      ) {
        this.show_ios_os_versions = false;
        this.show_android_os_versions = true;
      } else {
        this.show_ios_os_versions = false;
        this.show_android_os_versions = false;
      }
    },
    removeSelectedDevicesMaker(index) {
      this.selected_devices_makers.splice(index, 1);
    },
    removeSelectedCarriers(index) {
      this.selected_carriers.splice(index, 1);
    },
    formattedCarriers() {
      this.foramttedCarriers = this.carriers.map((carrier) => ({
        id: carrier.id,
        label: carrier.name,
      }));
    },
    getAllCarriers() {
      apiClient
        .get("/snapchat/carrier")
        .then((response) => {
          this.carriers = response.data.data.map((item) => item.carrier);
          this.formattedCarriers();
        })
        .catch((error) => {
          console.error("Error fetching gender data:", error);
        });
    },
    getAllDevices() {
      apiClient
        .get("/snapchat/devices-make")
        .then((response) => {
          this.devices_makers = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching gender data:", error);
        });
    },
  },
  watch: {
    iOS_devices(val) {
      if (!this.iOS_devices) {
        this.android_devices = true;
        this.android_readonly = true;
        this.iOS_readonly = false;
      } else {
        this.android_readonly = false;
        this.iOS_readonly = false;
      }
      this.showOsVersions();
      localStorage.setItem("iOS_devices", val);
    },
    android_devices(val) {
      if (!this.android_devices) {
        this.iOS_devices = true;
        this.android_readonly = false;
        this.iOS_readonly = true;
      } else {
        this.android_readonly = false;
        this.iOS_readonly = false;
      }
      this.showOsVersions();
      localStorage.setItem("android_devices", val);
    },
    web_devices(val) {
      this.showOsVersions();
      localStorage.setItem("web_devices", val);
    },
    max_android_version(val) {
      if (Number(this.max_android_version) < Number(this.min_android_version)) {
        this.max_android_version = this.min_android_version;
      }
      localStorage.setItem("max_android_version", val);
    },
    min_android_version(val) {
      if (Number(this.max_android_version) < Number(this.min_android_version)) {
        this.max_android_version = this.min_android_version;
      }
      localStorage.setItem("min_android_version", val);
    },
    min_ios_version(val) {
      if (Number(this.max_ios_version) < Number(this.min_ios_version)) {
        this.max_ios_version = this.min_ios_version;
      }
      localStorage.setItem("min_ios_version", val);
    },
    max_ios_version(val) {
      if (Number(this.max_ios_version) < Number(this.min_ios_version)) {
        this.max_ios_version = this.min_ios_version;
      }
      localStorage.setItem("max_ios_version", val);
    },
    selected_carriers(val) {
      localStorage.setItem("selected_carriers", JSON.stringify(val));
    },
  },
  mounted() {
    this.iOS_devices = localStorage.getItem("iOS_devices") === "true";
    this.android_devices = localStorage.getItem("android_devices") === "true";
    this.web_devices = localStorage.getItem("web_devices") === "true";
    this.getAllCarriers();
    this.getAllDevices();
  },
};
</script>
