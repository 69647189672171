<template>
  <button :type="type">
    <span>{{ textBtn }}</span>
    <img
      v-if="loginStore.loading"
      src="@/assets/loading.svg"
      width="30px"
      alt=""
    />
  </button>
</template>

<script>
import { loginForm } from "@/store/logIn";
// import { mapActions } from "pinia";
export default {
  props: ["textBtn", "type"],
  data() {
    return {
      loginStore: loginForm(),
    };
  },
};
</script>

<style scoped>
button {
  background: linear-gradient(88.77deg, #2e989e 18.67%, #174c4f 98.95%);
  border: 1px solid transparent;
  padding: 10px;
  width: 100%;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  width: 100%;
  margin: 1px auto;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
</style>
