<template>
  <div class="side-nav-parent-soft-ware h-100">
    <div ref="sideNavParent" class="side-nav-soft-ware px-0">
      <button
        class="mx-4 active"
        @click="toggleSideNav"
      >
        <div @click="dropDown('expand2')" class="d-flex align-center ga-5 justify-space-between">
          <div class="d-flex align-center ga-5 w-100 icon-text-contant">
            <img
              src="@/assets/soft-ware-img/online-advertising_12516043 1.svg"
              alt=""
            />
            <span >Ads</span>
          </div>
          <p @click="dropDown('expand2')">
            <span v-if="!expand2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="15"
                height="15"
                fill="rgba(31, 102, 106, 1)"
              >
                <path
                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                />
              </svg>
            </span>
            <span v-else>
              <svg
                width="15"
                height="15"
                fill="rgba(31, 102, 106, 1)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                />
              </svg>
            </span>
          </p>
        </div>
      </button>
      <v-expand-transition>
        <v-card
          v-show="expand2"
          class="pl-10 pr-4 bg-transparent"
          height=""
          width="100%"
          elevation="0"
        >
          <router-link to="/create-new-camp">
            <span>
              <img src="@/assets/soft-ware-img/Layer_x0020_1.svg" alt="" />
            </span>
            <span>Create ADs / Campaign</span>
          </router-link>
          <router-link to="/ads-performance">
            <span>
              <img src="@/assets/soft-ware-img/chartsIcon.svg" alt="" />
            </span>
            <span>ADs Performance</span>
          </router-link>
          <router-link to="/">
            <span>
              <img src="@/assets/soft-ware-img/Group_Keyworsd.svg" alt="" />
            </span>
            <span>Keywords Planner</span>
          </router-link>
        </v-card>
      </v-expand-transition>
      <button class="mx-4"  @click="toggleSideNav">
        <div class="d-flex align-center ga-5 justify-space-between">
          <div class="d-flex align-center ga-5 w-100 icon-text-contant">
            <img src="@/assets/soft-ware-img/leads-icon-side-bar.svg" alt="" />
            <span>Leads</span>
          </div>
        </div>
      </button>
      <button class="mx-4"  @click="toggleSideNav">
        <div @click="dropDown('expand3')" class="d-flex align-center ga-5 justify-space-between">
          <div class="d-flex align-center ga-5 w-100 icon-text-contant">
            <img
              src="@/assets/soft-ware-img/social-media-side-icon.svg"
              alt=""
            />
            <span >Social media</span>
          </div>
          <p @click="dropDown('expand3')">
            <span v-if="!expand3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="15"
                height="15"
                fill="rgba(31, 102, 106, 1)"
              >
                <path
                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                />
              </svg>
            </span>
            <span v-else>
              <svg
                width="15"
                height="15"
                fill="rgba(31, 102, 106, 1)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                />
              </svg>
            </span>
          </p>
        </div>
      </button>
      <v-expand-transition>
        <v-card
          v-show="expand3"
          class="pl-10 pr-4 bg-transparent"
          height=""
          width="100%"
          elevation="0"
        >
          <router-link to="/">
            <span>
              <img
                src="@/assets/soft-ware-img/discussion_14562101 1.svg"
                alt=""
              />
            </span>
            <span>Messages center</span>
          </router-link>
          <router-link to="/publish-post">
            <span>
              <img src="@/assets/soft-ware-img/review_768872 1.svg" alt="" />
            </span>
            <span>Publish posts</span>
          </router-link>
          <router-link to="/">
            <span>
              <img
                src="@/assets/soft-ware-img/engegment-insites-icon-side.svg"
                alt=""
              />
            </span>
            <span>Engament and insights</span>
          </router-link>
        </v-card>
      </v-expand-transition>

      <button class="mx-4"  @click="toggleSideNav">
        <div @click="dropDown('expand4')" class="d-flex align-center ga-5 justify-space-between">
          <div class="d-flex align-center ga-5 w-100 icon-text-contant">
            <img
              src="@/assets/soft-ware-img/paint-palette_12094326 1.svg"
              alt=""
            />
            <span >Designs</span>
          </div>
          <p @click="dropDown('expand4')">
            <span v-if="!expand4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="15"
                height="15"
                fill="rgba(31, 102, 106, 1)"
              >
                <path
                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                />
              </svg>
            </span>
            <span v-else>
              <svg
                width="15"
                height="15"
                fill="rgba(31, 102, 106, 1)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                />
              </svg>
            </span>
          </p>
        </div>
      </button>
      <v-expand-transition>
        <v-card
          v-show="expand4"
          class="pl-10 pr-4 bg-transparent"
          height=""
          width="100%"
          elevation="0"
        >
          <router-link to="/">
            <span>
              <img src="@/assets/soft-ware-img/createDesign.svg" alt="" />
            </span>
            <span>Create Design</span>
          </router-link>
          <router-link to="/">
            <span>
              <img src="@/assets/soft-ware-img/createLandingPage.svg" alt="" />
            </span>
            <span>Create Landing Page</span>
          </router-link>
          <router-link to="/">
            <span>
              <img src="@/assets/soft-ware-img/myDrive.svg" alt="" />
            </span>
            <span>My Drive</span>
          </router-link>
        </v-card>
      </v-expand-transition>
      <button class="mx-4"  @click="toggleSideNav">
        <div class="d-flex align-center ga-5 justify-space-between">
          <div class="d-flex align-center ga-5 w-100 icon-text-contant">
            <img src="@/assets/soft-ware-img/calender-icon.svg" alt="" />
            <span>Calender</span>
          </div>
        </div>
      </button>
      <button class="mx-4"  @click="toggleSideNav">
        <div @click="dropDown('expand5')" class="d-flex align-center ga-5 justify-space-between">
          <div class="d-flex align-center ga-5 w-100 icon-text-contant">
            <img src="@/assets/soft-ware-img/setting-icon.svg" alt="" />
            <span >Settings</span>
          </div>
          <p @click="dropDown('expand5')">
            <span v-if="!expand5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="15"
                height="15"
                fill="rgba(31, 102, 106, 1)"
              >
                <path
                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                />
              </svg>
            </span>
            <span v-else>
              <svg
                width="15"
                height="15"
                fill="rgba(31, 102, 106, 1)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                />
              </svg>
            </span>
          </p>
        </div>
      </button>
      <v-expand-transition>
        <v-card
          v-show="expand5"
          class="pl-10 pr-4 bg-transparent"
          height=""
          width="100%"
          elevation="0"
        >
          <router-link to="/">
            <span>
              <img src="@/assets/soft-ware-img/team-icon.svg" alt="" />
            </span>
            <span>My Team </span>
          </router-link>
          <router-link to="/">
            <span>
              <img src="@/assets/soft-ware-img/Finance-center.svg" alt="" />
            </span>
            <span>Finance Center</span>
          </router-link>
          <router-link to="/">
            <span>
              <img src="@/assets/soft-ware-img/integrations.svg" alt="" />
            </span>
            <span>Integrations</span>
          </router-link>
          <router-link to="/">
            <span>
              <img
                src="@/assets/soft-ware-img/my-account-side-icon.svg"
                alt=""
              />
            </span>
            <span>My Account</span>
          </router-link>
        </v-card>
      </v-expand-transition>

      <button  @click="closeSidebar" class="close-side-bar" type="button">
        <img src="@/assets/soft-ware-img/close-side-bar-icon.svg" alt="" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expand2: false,
      expand3: false,
      sideNavWidth: false,
      expand4: false,
      expand5: false,
      closeIcon: false,
    };
  },
  methods: {
    toggleSideNav() {
      this.sideNavWidth = !this.sideNavWidth;
      this.$refs.sideNavParent.style.width = "270px";
      this.closeIcon = true;
    },
    closeSidebar() {
      this.$refs.sideNavParent.style.width = "70px";
      this.closeIcon = false;
      this.expand2 = false;
      this.expand3 = false;
      this.expand4 = false;
      this.expand5 = false;
    },
    dropDown(property) {
      if (this[property]) {
        this[property] = false;
        return;
      }
      this.expand2 = false;
      this.expand3 = false;
      this.expand4 = false;
      this.expand5 = false;

      this[property] = true;
    },
  },
  computed: {
    isAnyLinkActive() {
      const linksContainer = this.$refs.linksContainer;
      if (!linksContainer) return false;

      // Check if any router-link within the container has 'link-exact-active'
      return Array.from(linksContainer.querySelectorAll('.link-exact-active')).length > 0;
    },
  },
};
</script>
